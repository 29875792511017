import * as React from "react";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

import titlelogo from "../assets/titlewithlogo.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

const headerTheme = createTheme({
  palette: {
    primary: {
      main: "#3B7EA1",
    },
  },
});

export default function Header() {
  const navigate = useNavigate();
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  const handleClick = (url) => {
    const saved = localStorage.getItem("saved");
    const input = document.getElementById("EnglishC3");
    const inputJapanese = document.querySelector(
      "#paragraph-support-container #Japanese"
    );
    let text = input?.value ? input?.value : "";
    let textJapanese = inputJapanese?.value ? inputJapanese?.value : "";

    if (
      saved !== "true" &&
      (`${text}`.length > 0 || `${textJapanese}`.length > 0)
    ) {
      const userConfirmed = window.confirm("保存せずに終了しますか?");
      if (userConfirmed) {
        navigate(url);
      }
    } else {
      navigate(url);
    }
  };

  const handleLogOut = async () => {
    const saved = localStorage.getItem("saved");
    const input = document.getElementById("EnglishC3");
    const inputJapanese = document.querySelector(
      "#paragraph-support-container #Japanese"
    );
    let text = input?.value ? input?.value : "";
    let textJapanese = inputJapanese?.value ? inputJapanese?.value : "";

    if (
      saved !== "true" &&
      (`${text}`.length > 0 || `${textJapanese}`.length > 0)
    ) {
      const userConfirmed = window.confirm("保存せずに終了しますか?");
      if (userConfirmed) {
        await user.signOut();
        window.location.replace(`${window.location.origin}/login`);
      }
    } else {
      await user.signOut();
      window.location.replace(`${window.location.origin}/login`);
    }
  };
  let button;
  if (authStatus !== "authenticated") {
    button = (
      <Link component={RouterLink} to="/login">
        <Button variant="contained" disableElevation>
          ログイン
        </Button>
      </Link>
    );
  } else {
    button = (
      <Grid gap={2}>
        <Button
          variant="contained"
          disableElevation
          sx={{ margin: "3px" }}
          onClick={() => handleClick("/mypage")}
        >
          マイページ
        </Button>
        <Button onClick={handleLogOut} variant="outlined" disableElevation>
          ログアウト
        </Button>
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={headerTheme}>
      <Box
        align="center"
        paddingTop={1.5}
        minWidth="360px"
        className="header-page"
        sx={{
          borderBottom: 1,
          paddingBottom: 2,
          borderColor: "divider",
          position: "relative",
        }}
      >
        <Grid maxHeight="40px">
          <a href="/">
            <img
              src={titlelogo}
              alt="Transable"
              style={{ height: `40px` }}
            ></img>
          </a>
        </Grid>
        <Grid item className="login-button">
          {button}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
