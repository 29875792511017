import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { Box, Typography, Link } from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { EssaySupportModal } from './components/Mypage/EssaySupportModal'
import { ParagraphSupportModal } from './components/Mypage/ParagraphSupportModal'
import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import CircularProgress from '@mui/material/CircularProgress';
import { PaginationControl } from './components/Mypage/PaginaionControl';
import { Link as RouterLink } from "react-router-dom";

import {getDataByUserEmail} from "./services/transable";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#e0c059',
    color: theme.palette.common.dark,
    fontWeight: '600',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "pre-line",
    padding: "12px"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
},
}));

export const StyledHeader = styled('h3')(() => ({
  marginBottom: "2px"
}));

export const StyledCode = styled(Typography)(() => ({
  whiteSpace: "pre-line",
  padding: "14px 8px",
  color: "#1a1e23",
  backgroundColor: "#f6f6f6",
  minHeight: "50px",
  border: "1px dotted #dedede",
  borderRadius: "4px",
  fontSize: "14px"
}));

const TruncateText = ({ text, maxLength = 30 }) => {
  const truncatedText = text ? text?.slice(0, maxLength) + `${ text.length > maxLength ? '...' : '' }` : "" ;
  return (
    <Typography sx = {{ fontSize: 14 }}>
      {truncatedText}
    </Typography>
  );
};

function Mypage() {
  const [openEssayModal, setOpenEssayModal] = React.useState(false);
  const [openParagraphModal, setOpenParagraphModal] = React.useState(false);
  const [essays, setEssays] = React.useState([]);
  const [essay, setEssay] = React.useState({});
  const [paragraphs, setParagraphs] = React.useState([]);
  const [paragraph, setParagraph] = React.useState({});
  const { user } = useAuthenticator();
  const [loading, setLoading] = React.useState(true);
  const [paragraphLoading, setParagraphLoading] = React.useState(true);
  const [paragraphCurrentPage, setParagraphCurrentPage] = React.useState(1);
  const [essayCurrentPage, setEssayCurrentPage] = React.useState(1);

  const perpage = 10
  const [paragraphsPaginated, setParagraphsPaginated] = React.useState([]);
  const [essaysPaginated, setEssaysPaginated] = React.useState([]);

  if (!user) {
    window.location.href = "/login";
  }

  // TODO: update this function/ handle show data to modal
  const handleOpenEssayModal = (essay) => {
    setEssay(essay);
    setOpenEssayModal(true);
  };
  const handleCloseEssayModal = () => {
    setOpenEssayModal(false);
  };

  const handleOpenParagraphModal = (paragraph) => {
    setParagraph(paragraph);
    setOpenParagraphModal(true);
  };
  const handleCloseParagraphModal = () => {
    setOpenParagraphModal(false);
  };

  const initData = async () => {
    const data = await getDataByUserEmail(user?.attributes.email)
    setEssays(data.data.essays);
    setParagraphs(data.data.paragraphs);
    setLoading(false);
    setParagraphLoading(false)
  }

  React.useEffect(() => {
    initData()
  }, [])

  const handleParagraphPageChange = (event, value) => {
    setParagraphCurrentPage(value);
  }

  const handleEssayPageChange = (event, value) => {
    setEssayCurrentPage(value);
  }

  React.useEffect(() => {
    const startIndex = (essayCurrentPage - 1) * perpage;
    const endIndex = Math.min(startIndex + perpage, essays.length);
    const currentPageData = essays.slice(startIndex, endIndex);

    setEssaysPaginated(currentPageData)
  }, [essays, essayCurrentPage])

  React.useEffect(() => {
    const startIndex = (paragraphCurrentPage - 1) * perpage;
    const endIndex = Math.min(startIndex + perpage, paragraphs.length);
    const currentPageData = paragraphs.slice(startIndex, endIndex);

    setParagraphsPaginated(currentPageData)
  }, [paragraphs, paragraphCurrentPage])

 return (
  <Grid container  maxWidth="xl" sx={{ m: "0 auto", px: 4 }}>
      <Grid xs={12}>
        <Box
          component="h3"
          sx={{
              display: 'flex',
              justifyContent: "space-between",
              flexGrow: 1,
              borderColor: "divider",
          }}
        >
          <Link component={RouterLink} to="/" color="inherit">
              {'< トップページに戻る'}
          </Link>
          {user?.attributes.email}
        </Box>
      </Grid>

      <EssaySupportModal 
          handleClose={handleCloseEssayModal}
          open={openEssayModal}
          essay={essay}
          setEssays={setEssays}
          setLoading={setLoading}
      />
      <ParagraphSupportModal 
          handleClose={handleCloseParagraphModal}
          open={openParagraphModal}
          paragraph={paragraph}
          setParagraphs={setParagraphs}
          setLoading={setParagraphLoading} 
      />
      <br/>
      <Grid item xs={12} md={12}>
          <Box
              sx={{
                  justifyContent: "center",
                  flexGrow: 1,
                  borderColor: "divider",
              }}
          >
            <h2>[ ESSAY SUPPORT ]</h2>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell >最終保存日時</StyledTableCell>
                        <StyledTableCell >英文エッセイ</StyledTableCell>
                        <StyledTableCell >日本語訳</StyledTableCell>
                        <StyledTableCell >評価内容</StyledTableCell>
                        <StyledTableCell align="right">アクション</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ position: 'relative' }} height={ loading || !essays.length > 0 ? 200 : null }>
                      {
                        loading ?
                          <TableRow>
                            <StyledTableCell colSpan={5}>
                              <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                            </StyledTableCell>
                          </TableRow> :
                        essays.length > 0 ?
                          essaysPaginated.map(i => (<StyledTableRow key={i.transable_id} >
                            <StyledTableCell component="th" scope="row">
                              {i.updated_at}
                            </StyledTableCell>
                            <StyledTableCell sx={{width: "25%"}} >
                              <TruncateText text={i.english_text}/>
                            </StyledTableCell>
                            <StyledTableCell sx={{width: "30%"}}>
                              <TruncateText text={i.japanese_translation}/>
                            </StyledTableCell>
                            <StyledTableCell >
                              <TruncateText text={i.evaluation_details}/>
                            </StyledTableCell>
                            <StyledTableCell sx={{width: "7%"}} align="right">
                              <Button onClick={() => { handleOpenEssayModal(i) }} variant="contained" disableElevation>
                                詳細
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>))
                        : <Typography style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>データなし</Typography>
                      }
                    </TableBody>
                  </Table>
                  <PaginationControl total={essays.length} currentPage={essayCurrentPage} handlePageChange={handleEssayPageChange} perpage={perpage} />
              </TableContainer>
          </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box py={4} sx={{ justifyContent: "center", flexGrow: 1, borderColor: "divider" }}>
          <h2>[ PARAGRAPH SUPPORT ]</h2>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell >最終保存日時</StyledTableCell>
                    <StyledTableCell >短めの文章</StyledTableCell>
                    <StyledTableCell >AIの提案  </StyledTableCell>
                    <StyledTableCell >英文パラグラフ</StyledTableCell>
                    <StyledTableCell >日本語訳</StyledTableCell>
                    <StyledTableCell >評価内容</StyledTableCell>
                    <StyledTableCell align="right">アクション</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ position: 'relative' }} height={ paragraphLoading || !paragraphs.length > 0 ? 200 : null }>
                  {
                    paragraphLoading ?
                      <TableRow>
                        <StyledTableCell colSpan={7} >
                          <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </StyledTableCell>
                      </TableRow> :
                    paragraphs.length > 0 ?
                      paragraphsPaginated.map(i => (<StyledTableRow key={i.transable_id} >
                        <StyledTableCell component="th" scope="row">
                          {i.updated_at}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "20%"}}>
                          <TruncateText text={i.japanese_short_sentences} />
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "20%"}} >
                          <TruncateText text={i.ai_suggestions}></TruncateText>
                        </StyledTableCell>
                        <StyledTableCell>
                          <TruncateText text={i.english_text}></TruncateText>
                        </StyledTableCell>
                        <StyledTableCell>
                          <TruncateText text={i.japanese_translation}/>
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "20%"}}>
                          <TruncateText text={i.evaluation_details}></TruncateText>
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "7%"}} align="right">
                          <Button onClick={() => handleOpenParagraphModal(i)} variant="contained" disableElevation>
                            詳細
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>))
                    : <Typography style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>データなし</Typography>
                  }
                </TableBody>
            </Table>
            <PaginationControl total={paragraphs.length} currentPage={paragraphCurrentPage} handlePageChange={handleParagraphPageChange} perpage={perpage} />
        </TableContainer>
      </Box>
    </Grid>
  </Grid>
  )
}


export default Mypage;