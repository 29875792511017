import React from "react";
import {
  Typography,
  Link,
  Box,
  Card,
  CardContent,
  Paper,
  Grid,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SchoolIcon from "@mui/icons-material/School";

function AccountCreation() {
  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h5" gutterBottom>
        （教育機関利用限定）個人用アカウント作成
      </Typography>
      <Typography variant="body1" style={{ marginTop: "20px" }}>
        下記から該当する方を選んで、アカウントを作成することができます。
      </Typography>
      <Typography variant="body2" style={{ marginTop: "20px" }}>
        アカウント発行には数日を要します。発行完了はメールでお知らせします。迷惑メールBOXにも入っていないかご確認ください。
        <br />
        当面は無料でご利用いただけます。有料になる時はあらためて連絡いたします。
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ marginTop: "20px", padding: "0 20px" }}
      >
        <Grid item sx={12}>
          <Paper
            elevation={3}
            style={{ padding: "20px", backgroundColor: "#f5f5f5" }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "20px",
                }}
              >
                <Box
                  style={{
                    flex: "1",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    style={{
                      background:
                        "linear-gradient(135deg, #EDC33F 30%, #f9eabd 90%)",
                      borderRadius: "50%",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <PersonAddIcon
                      style={{ fontSize: "30px", color: "#fff" }}
                    />
                  </Box>
                </Box>
                <CardContent style={{ flex: "2" }}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    これから教育機関で利用したい先生 （教員経由のみ）
                  </Typography>
                </CardContent>
              </Box>

              <CardContent>
                <Typography variant="body2" color="textSecondary" paragraph>
                  これから教育機関で利用したい先生はこちらからアカウントを作成してください。新規アカウントの登録ができます。生徒個人の登録については2024年7月にお知らせいたします。
                </Typography>
                <Link
                  component={RouterLink}
                  to="https://form.jotform.com/241519206790255"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="button"
                  style={{
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#EDC33F",
                    color: "#000",
                    borderRadius: "20px",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  新規に利用開始する
                </Link>
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        <Grid item sx={12}>
          <Paper
            elevation={3}
            style={{ padding: "20px", backgroundColor: "#f5f5f5" }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "20px",
                }}
              >
                <Box
                  style={{
                    flex: "1",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    style={{
                      background:
                        "linear-gradient(135deg, #3B7EA1 30%, #90CAF9 90%)",
                      borderRadius: "50%",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <SchoolIcon style={{ fontSize: "30px", color: "#fff" }} />
                  </Box>
                </Box>

                <CardContent style={{ flex: "2" }}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    すでに教育機関で <br />
                    利用している 先生・生徒
                  </Typography>
                </CardContent>
              </Box>

              <CardContent>
                <Typography variant="body2" color="textSecondary" paragraph>
                  これまでに"xxx@transable.net"のメールアドレスでログインしたことがある先生および生徒が対象です。ご自身のメールアドレスでログインできるようになります。
                </Typography>
                <Link
                  component={RouterLink}
                  to="https://form.jotform.com/241150331849250"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="button"
                  style={{
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#3B7EA1",
                    color: "#FFF",
                    borderRadius: "20px",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  自分のアカウントを作る
                </Link>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AccountCreation;
