import { Auth } from "aws-amplify";

export const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (err) {
    console.log(err);
  }
};

export const authenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (err) {
    return false;
  }
};
