// // import React from "react";
// // import { Grid, TextField } from "@mui/material";

// // import SelectMenuButtonforEssay from "../EssaySupport/SelectMenusAndButtonforEssay";

// // export default function App({ questionType }) {
// //   return (
// //     <Grid container mt={1}>
// //       <SelectMenuButtonforEssay questionType={questionType} />

// //       <Grid item xs={12} sx={{ marginTop: 0, marginBottom: 0 }}>
// //         <TextField
// //           fullWidth
// //           multiline
// //           minRows={4}
// //           maxRows={9}
// //           InputProps={{
// //             style: { fontSize: 16, lineHeight: 1.5 },
// //             readOnly: true,
// //           }}
// //           id="C3_essayEvaluation"
// //           label=""
// //           placeholder="評価結果が表示されます"
// //           variant="outlined"
// //           sx={{
// //             background: "#f1f3f4",
// //             "& .MuiOutlinedInput-notchedOutline": {
// //               borderTopLeftRadius: 0,
// //               borderBottomRightRadius: 0,
// //             },
// //             "& :hover .MuiOutlinedInput-notchedOutline": {
// //               borderColor: "#c5c5c5",
// //             },
// //             "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
// //               border: 1,
// //               borderColor: "#c5c5c5",
// //             },
// //           }}
// //         />
// //       </Grid>
// //     </Grid>
// //   );
// // }

// // import React, { useState } from "react";
// // import { Button, Grid, TextField, Box, Paper } from "@mui/material";
// // import ReactMarkdown from "react-markdown";
// // import rehypeRaw from "rehype-raw";
// // import SelectMenuButtonforEssay from "../EssaySupport/SelectMenusAndButtonforEssay";
// // import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

// // import { APIGateWay, APILambda } from "../../constants/api";
// // import { getToken, authenticated } from "../../utils/amplify";

// // export default function App({ questionType }) {
// //   const [loading, setLoading] = useState(false);
// //   const [markdownContent, setMarkdownContent] = useState();

// //   const handleChatGPT = async () => {
// //     setLoading(true);
// //     setMarkdownContent(
// //       `
// //     # これはサンプルのマークダウンテキストです

// //     - アイテム1
// //     - アイテム2

// //     **太字のテキスト**

// //     <p style="color: blue;">このテキストは青色です。</p>
// //     <p>このテキストは <span style="color: red;">赤色の<span>部分を含んでいます。</p>

// //     ## 表のサンプル

// //     | ヘッダー1 | ヘッダー2 |
// //     | -------- | -------- |
// //     | 内容1 | 内容2 |
// //     | 内容3 | 内容4 |

// //     ## 画像のサンプル

// //     ![サンプル画像](https://via.placeholder.com/150)
// //     `
// //     );

// //     // const selectedQuestion = QUESTION_TYPES.find((q) => q.value === question);
// //     // if (!selectedQuestion) {
// //     //   // alert("Error! 評価方法の種類を選んでください");
// //     //   setLoading(false);
// //     //   document.getElementById("C3_essayEvaluation").value =
// //     //     "　⚠️ 評価方法の種類を選んでください";
// //     //   return;
// //     // }

// //     // if (!prompt) {
// //     //   document.getElementById("C3_essayEvaluation").value =
// //     //     "　⚠️ 何も入力されていません。";
// //     //   setLoading(false);
// //     //   return;
// //     // }

// //     // let API_ENDPOINT = APILambda.chatGpt;
// //     // let headers = {};

// //     // if (await authenticated()) {
// //     //   API_ENDPOINT = APIGateWay.chatGpt;
// //     //   headers = {
// //     //     Authorization: "Bearer " + (await getToken()),
// //     //   };
// //     // }

// //     // const requestBody = JSON.stringify({
// //     //   model: "gpt-3.5-turbo",
// //     //   messages: [
// //     //     // ...selectedQuestion.questions,
// //     //     { role: "user", content: prompt },
// //     //   ],
// //     // });

// //     // try {
// //     //   const response = await fetch(API_ENDPOINT, {
// //     //     method: "POST",
// //     //     headers,
// //     //     body: requestBody,
// //     //   });
// //     //   if (!response.ok) {
// //     //     throw new Error("Network response was NOT ok");
// //     //   }
// //     //   const data = await response.json();
// //     //   document.getElementById("C3_essayEvaluation").value =
// //     //     data.choices[0].message.content;
// //     // } catch (error) {
// //     //   document.getElementById("C3_essayEvaluation").value =
// //     //     "　⚠️ エラーが発生しました";
// //     //   console.error(error);
// //     // } finally {
// //     setLoading(false);
// //     // }
// //   };

// //   return (
// //     <Grid container sx={{ padding: 0 }}>
// //       <Grid item xs={12} md={6} sx={{ marginTop: 0, marginBottom: 0 }}>
// //         <SelectMenuButtonforEssay questionType={questionType} />
// //         <TextField
// //           fullWidth
// //           multiline
// //           minRows={8}
// //           maxRows={16}
// //           InputProps={{
// //             style: { background: "#f1f3f4", fontSize: 16, lineHeight: 1.5 },
// //             readOnly: true,
// //           }}
// //           id="C3_essayEvaluation"
// //           label=""
// //           placeholder="評価結果が表示されます"
// //           variant="outlined"
// //           sx={{
// //             "& .MuiOutlinedInput-notchedOutline": {
// //               borderTopLeftRadius: 0,
// //               borderTopRightRadius: 0,
// //               borderBottomRightRadius: 0,
// //             },
// //             "& :hover .MuiOutlinedInput-notchedOutline": {
// //               // borderColor: "#c5c5c5",
// //             },
// //             "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
// //               border: 1,
// //               borderColor: "#c5c5c5",
// //             },
// //           }}
// //         />
// //       </Grid>

// //       <Grid item xs={12} md={6} sx={{ marginTop: 1, marginBottom: 0 }}>
// //         <Button
// //           size="small"
// //           variant="contained"
// //           disableElevation
// //           onClick={handleChatGPT}
// //           startIcon={<TipsAndUpdatesIcon />}
// //           width="50%"
// //           sx={{
// //             paddingBlock: 0.75,
// //             fontSize: 14,
// //             textTransform: "capitalize",
// //             borderBottomLeftRadius: 0,
// //             borderBottomRightRadius: 0,
// //           }}
// //           disabled={loading}
// //         >
// //           {loading ? "読み込み中..." : "意見をもらう"}
// //         </Button>
// //         <Paper
// //           elevation={1}
// //           sx={{
// //             padding: 2,
// //             background: "#f1f3f4",
// //             borderTopLeftRadius: 0,
// //             borderBottomRightRadius: 0,
// //             "&:hover": {
// //               borderColor: "#c5c5c5",
// //             },
// //             "&.Mui-focused": {
// //               border: 1,
// //               borderColor: "#c5c5c5",
// //             },
// //           }}
// //         >
// //           <ReactMarkdown
// //             children={markdownContent}
// //             rehypePlugins={[rehypeRaw]}
// //             components={{
// //               h1: ({ node, ...props }) => (
// //                 <Box
// //                   component="h1"
// //                   sx={{ fontSize: 24, fontWeight: "bold" }}
// //                   {...props}
// //                 />
// //               ),
// //               p: ({ node, ...props }) => (
// //                 <Box
// //                   component="p"
// //                   sx={{ marginBottom: 1.5, lineHeight: 1.5 }}
// //                   {...props}
// //                 />
// //               ),
// //               li: ({ node, ...props }) => (
// //                 <Box
// //                   component="li"
// //                   sx={{ marginLeft: 2, marginBottom: 1 }}
// //                   {...props}
// //                 />
// //               ),
// //               strong: ({ node, ...props }) => (
// //                 <Box
// //                   component="strong"
// //                   sx={{ fontWeight: "bold" }}
// //                   {...props}
// //                 />
// //               ),
// //               table: ({ node, ...props }) => (
// //                 <Box
// //                   component="table"
// //                   sx={{ width: "100%", borderCollapse: "collapse" }}
// //                   {...props}
// //                 />
// //               ),
// //               th: ({ node, ...props }) => (
// //                 <Box
// //                   component="th"
// //                   sx={{
// //                     border: "1px solid #ddd",
// //                     padding: "8px",
// //                     backgroundColor: "#f2f2f2",
// //                   }}
// //                   {...props}
// //                 />
// //               ),
// //               td: ({ node, ...props }) => (
// //                 <Box
// //                   component="td"
// //                   sx={{ border: "1px solid #ddd", padding: "8px" }}
// //                   {...props}
// //                 />
// //               ),
// //               img: ({ node, ...props }) => (
// //                 <Box
// //                   component="img"
// //                   sx={{ maxWidth: "100%", height: "auto" }}
// //                   {...props}
// //                 />
// //               ),
// //             }}
// //           />
// //         </Paper>
// //       </Grid>
// //     </Grid>
// //   );
// // }

// import React, { useState } from "react";
// import { Button, Grid, TextField, Box } from "@mui/material";
// import ReactMarkdown from "react-markdown";
// import rehypeRaw from "rehype-raw";
// import SelectMenuButtonforEssay from "../EssaySupport/SelectMenusAndButtonforEssay";
// import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

// import { APIGateWay, APILambda } from "../../constants/api";
// import { getToken, authenticated } from "../../utils/amplify";

// const replacePlaceholders = (template, replacements) => {
//   return template.replace(
//     /\{\{(\w+)\}\}/g,
//     (_, key) => replacements[key] || ""
//   );
// };

// export default function App({ questionType }) {
//   const [loading, setLoading] = useState(false);
//   const [markdownContent, setMarkdownContent] = useState("");

//   const handleChatGPT = async () => {
//     setLoading(true);

//     let API_ENDPOINT = APILambda.chatGpt;
//     let headers = {};

//     if (await authenticated()) {
//       API_ENDPOINT = APIGateWay.chatGpt;
//       headers = {
//         Authorization: "Bearer " + (await getToken()),
//       };
//     }

//     const requestBody = JSON.stringify({
//       model: "gpt-3.5-turbo",
//       messages: [
//         {
//           role: "system",
//           content: replacePlaceholders(
//             process.env.REACT_APP_ChatGPTSetting_essayopinion_1_system
//           ),
//         },
//         {
//           role: "assistant",
//           content: replacePlaceholders(
//             process.env.REACT_APP_ChatGPTSetting_essayopinion_1_assistant
//           ),
//         },
//         {
//           role: "user",
//           content: replacePlaceholders(
//             process.env.REACT_APP_ChatGPTSetting_essayopinion_1_user,
//             { essayEnglish, essayValuationText }
//           ),
//         },
//       ],
//     });

//     try {
//       const response = await fetch(API_ENDPOINT, {
//         method: "POST",
//         headers,
//         body: requestBody,
//       });
//       if (!response.ok) {
//         throw new Error("Network response was NOT ok");
//       }
//       const data = await response.json();
//       setMarkdownContent(data.choices[0].message.content);
//     } catch (error) {
//       setMarkdownContent("　⚠️ エラーが発生しました");
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }

//     //     const sampleMarkdown = `
//     // # これはサンプルのマークダウンテキストです

//     // - アイテム1
//     // - アイテム2

//     // **太字のテキスト**

//     // <p style="color: blue;">このテキストは青色です。</p>
//     // <p>このテキストは <span style="color: red;">赤色の</span>部分を含んでいます。</p>

//     // ## 表のサンプル

//     // | ヘッダー1 | ヘッダー2 |
//     // | -------- | -------- |
//     // | 内容1 | 内容2 |
//     // | 内容3 | 内容4 |

//     // ## 画像のサンプル

//     // ![サンプル画像](https://via.placeholder.com/150)
//     // `;

//     // setMarkdownContent(sampleMarkdown);
//     setLoading(false);
//   };

//   return (
//     <Grid container sx={{ padding: 0 }}>
//       <Grid item xs={12} md={6} sx={{ marginTop: 0, marginBottom: 0 }}>
//         <SelectMenuButtonforEssay questionType={questionType} />
//         <TextField
//           fullWidth
//           multiline
//           minRows={8}
//           maxRows={16}
//           InputProps={{
//             style: {
//               background: "#f1f3f4",
//               fontSize: 16,
//               lineHeight: 1.5,
//               borderTopLeftRadius: "0",
//             },
//             readOnly: true,
//           }}
//           id="C3_essayEvaluation"
//           label=""
//           placeholder="評価結果が表示されます"
//           variant="outlined"
//         />
//       </Grid>

//       <Grid item xs={12} md={6} sx={{ marginTop: 1, marginBottom: 0 }}>
//         <Button
//           size="small"
//           variant="contained"
//           disableElevation
//           onClick={handleChatGPT}
//           startIcon={<TipsAndUpdatesIcon />}
//           width="50%"
//           sx={{
//             paddingBlock: 0.75,
//             fontSize: 14,
//             textTransform: "capitalize",
//             borderBottomLeftRadius: 0,
//             borderBottomRightRadius: 0,
//           }}
//           disabled={loading}
//         >
//           {loading ? "読み込み中..." : "意見をもらう"}
//         </Button>
//         <Box
//           elevation={0}
//           sx={{
//             margin: 0,
//             background: "#f1f3f4",
//             "& .MuiOutlinedInput-notchedOutline": {
//               borderTop: 0,
//             },
//             "& :hover .MuiOutlinedInput-notchedOutline": {
//               borderColor: "#c5c5c5",
//             },
//             "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
//               border: 1,
//               borderColor: "#c5c5c5",
//             },
//             padding: 2,
//             border: "1px solid #c5c5c5",
//             borderTopRightRadius: "4px",
//             borderTopLeftRadius: "0",
//             borderBottomRightRadius: "4px",
//             borderBottomLeftRadius: "4px",
//             minHeight: "225px",
//             maxHeight: "400px",
//             overflowY: "auto",
//             fontSize: 14,
//           }}
//         >
//           <ReactMarkdown
//             children={markdownContent}
//             rehypePlugins={[rehypeRaw]}
//             components={{
//               h1: ({ node, ...props }) => (
//                 <Box
//                   component="h1"
//                   sx={{ fontSize: 24, fontWeight: "bold" }}
//                   {...props}
//                 />
//               ),
//               p: ({ node, ...props }) => (
//                 <Box
//                   component="p"
//                   sx={{ marginBottom: 1.5, lineHeight: 1.5 }}
//                   {...props}
//                 />
//               ),
//               li: ({ node, ...props }) => (
//                 <Box
//                   component="li"
//                   sx={{ marginLeft: 2, marginBottom: 1 }}
//                   {...props}
//                 />
//               ),
//               strong: ({ node, ...props }) => (
//                 <Box
//                   component="strong"
//                   sx={{ fontWeight: "bold" }}
//                   {...props}
//                 />
//               ),
//               table: ({ node, ...props }) => (
//                 <Box
//                   component="table"
//                   sx={{ width: "100%", borderCollapse: "collapse" }}
//                   {...props}
//                 />
//               ),
//               th: ({ node, ...props }) => (
//                 <Box
//                   component="th"
//                   sx={{
//                     border: "1px solid #ddd",
//                     padding: "8px",
//                     backgroundColor: "#f2f2f2",
//                   }}
//                   {...props}
//                 />
//               ),
//               td: ({ node, ...props }) => (
//                 <Box
//                   component="td"
//                   sx={{ border: "1px solid #ddd", padding: "8px" }}
//                   {...props}
//                 />
//               ),
//               img: ({ node, ...props }) => (
//                 <Box
//                   component="img"
//                   sx={{ maxWidth: "100%", height: "auto" }}
//                   {...props}
//                 />
//               ),
//             }}
//           />
//         </Box>
//       </Grid>
//     </Grid>
//   );
// }

// 2つ代入エラー以外うまくいく

import React, { useState } from "react";
import { Button, Grid, TextField, Box } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import SelectMenuButtonforEssay from "../EssaySupport/SelectMenusAndButtonforEssay";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

import { APIGateWay, APILambda } from "../../constants/api";
import { getToken, authenticated } from "../../utils/amplify";

const replacePlaceholders = (template, replacements) => {
  return template.replace(
    /\{\{(\w+)\}\}/g,
    (_, key) => replacements[key] || ""
  );
};

export default function App({ questionType }) {
  const [loading, setLoading] = useState(false);
  const [markdownContent, setMarkdownContent] = useState("");

  const handleChatGPT = async () => {
    setLoading(true);

    const essayEnglish = document.getElementById("EnglishC3").value;
    const essayValuationText =
      document.getElementById("C3_essayEvaluation").value;

    // Abort if no text is input
    if (
      !essayValuationText ||
      essayValuationText.trim() === "" ||
      essayValuationText.includes("⚠️")
    ) {
      setMarkdownContent("　⚠️ 評価結果が正しく出力されていますか？");
      setLoading(false);
      return;
    }
    let API_ENDPOINT = APILambda.chatGpt;
    let headers = {};

    if (await authenticated()) {
      API_ENDPOINT = APIGateWay.chatGpt;
      headers = {
        Authorization: "Bearer " + (await getToken()),
      };
    }

    const requestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: replacePlaceholders(
            process.env.REACT_APP_ChatGPTSetting_essayopinion_1_system
          ),
        },
        {
          role: "assistant",
          content: replacePlaceholders(
            process.env.REACT_APP_ChatGPTSetting_essayopinion_1_assistant
          ),
        },
        {
          role: "user",
          content: replacePlaceholders(
            process.env.REACT_APP_ChatGPTSetting_essayopinion_1_user,
            { essayEnglish, essayValuationText }
          ),
        },
      ],
    });

    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers,
        body: requestBody,
      });
      if (!response.ok) {
        throw new Error("Network response was NOT ok");
      }
      const data = await response.json();
      setMarkdownContent(data.choices[0].message.content);
    } catch (error) {
      setMarkdownContent("　⚠️ エラーが発生しました");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container sx={{ padding: 0 }}>
      <Grid item xs={12} md={6} sx={{ marginTop: 0, marginBottom: 0 }}>
        <SelectMenuButtonforEssay questionType={questionType} />
        <TextField
          fullWidth
          multiline
          minRows={8}
          maxRows={16}
          InputProps={{
            style: {
              background: "#f1f3f4",
              fontSize: 16,
              lineHeight: 1.5,
              borderTopLeftRadius: "0",
            },
            readOnly: true,
          }}
          id="C3_essayEvaluation"
          label=""
          placeholder="評価結果が表示されます"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ marginTop: 1, marginBottom: 0 }}>
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={handleChatGPT}
          startIcon={<TipsAndUpdatesIcon />}
          width="50%"
          sx={{
            paddingBlock: 0.75,
            fontSize: 14,
            textTransform: "capitalize",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
          disabled={loading}
        >
          {loading ? "読み込み中..." : "意見をもらう"}
        </Button>
        <Box
          elevation={0}
          sx={{
            margin: 0,
            background: "#f1f3f4",
            "& .MuiOutlinedInput-notchedOutline": {
              borderTop: 0,
            },
            "& :hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#c5c5c5",
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: 1,
              borderColor: "#c5c5c5",
            },
            padding: 2,
            border: "1px solid #c5c5c5",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "0",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            minHeight: "225px",
            maxHeight: "417px",
            overflowY: "auto",
            fontSize: 16,
            userSelect: "none", // 追加部分
          }}
        >
          <ReactMarkdown
            children={markdownContent}
            rehypePlugins={[rehypeRaw]}
            components={{
              h1: ({ node, ...props }) => (
                <Box
                  component="h1"
                  sx={{ fontSize: 24, fontWeight: "bold" }}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <Box
                  component="p"
                  sx={{ marginBottom: 1.5, lineHeight: 1.5 }}
                  {...props}
                />
              ),
              li: ({ node, ...props }) => (
                <Box
                  component="li"
                  sx={{ marginLeft: 2, marginBottom: 1 }}
                  {...props}
                />
              ),
              strong: ({ node, ...props }) => (
                <Box
                  component="strong"
                  sx={{ fontWeight: "bold" }}
                  {...props}
                />
              ),
              table: ({ node, ...props }) => (
                <Box
                  component="table"
                  sx={{ width: "100%", borderCollapse: "collapse" }}
                  {...props}
                />
              ),
              th: ({ node, ...props }) => (
                <Box
                  component="th"
                  sx={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    backgroundColor: "#f2f2f2",
                  }}
                  {...props}
                />
              ),
              td: ({ node, ...props }) => (
                <Box
                  component="td"
                  sx={{ border: "1px solid #ddd", padding: "8px" }}
                  {...props}
                />
              ),
              img: ({ node, ...props }) => (
                <Box
                  component="img"
                  sx={{ maxWidth: "100%", height: "auto" }}
                  {...props}
                />
              ),
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
