import React, { useState } from "react";
import {
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const AlcSearchForm = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [iframeSrc, setIframeSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!searchQuery.trim()) {
      setError("検索クエリを入力してください。");
      return;
    }
    setError("");
    setLoading(true);
    const queryUrl = `https://eow.alc.co.jp/search?q=${encodeURIComponent(
      searchQuery
    )}`;
    setIframeSrc(queryUrl);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setError("");
  };

  const handleClearIframe = () => {
    setIframeSrc("");
    setLoading(false);
  };

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div
      style={{
        padding: "0px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", width: "100%" }}
            aria-labelledby="search-form"
          >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="検索したい英単語や短文"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              error={!!error}
              helperText={error}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={handleClearSearch}
                      edge="start"
                      aria-label="clear search"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit" edge="end" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>
      </Grid>

      {iframeSrc && (
        <div style={{ marginTop: "16px", position: "relative" }}>
          <IconButton
            aria-label="clear iframe"
            onClick={handleClearIframe}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <HighlightOffIcon />
          </IconButton>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </div>
          )}
          <iframe
            src={iframeSrc}
            width="100%"
            height="450px"
            style={{ border: "1px solid #ccc" }}
            title="ALC Search Results"
            onLoad={handleIframeLoad}
            onError={() => setLoading(false)}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default AlcSearchForm;
