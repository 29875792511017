import React from "react";
import { Container } from "@mui/material";

function UserPolicyFree() {
  return (
    <Container sx={{ flexGrow: 1 }}>
      <p>
        <h1>アカウントが無い方の利用規約</h1>
        本利用規約（以下「本規約」と言います。）には、Transableの著作権者が提供するサービス（以下「本サービス」と言います。）の提供条件及び当方と非登録ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
        <h3>第1条（適用）</h3>
        <ol>
          <li>
            本規約は、本サービスの提供条件及び本サービスの利用に関する当方とアカウント登録をしていない非登録ユーザーとの間の権利義務関係を定めることを目的とし、非登録ユーザーと本サービスの利用に関わる一切の関係に適用されます。
          </li>
          <li>
            当方が当方ウェブサイト上で掲載するサービス利用に関するルールは、本規約の一部を構成するものとします。
          </li>
          <li>
            本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。ただし、本サービスの利用の登録をなされたユーザーは「アカウントが有る方の利用規約」の規定が優先して適用されます。
          </li>
        </ol>
        <h3>第2条（定義）</h3>
        本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
        <ol>
          <li>
            「サービス利用契約」とは、本規約を契約条件として当方と非登録ユーザーの間で締結される、本サービスの利用契約を意味します。
          </li>
          <li>
            「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
          </li>
          <li>
            「投稿データ」とは、非登録ユーザーが本サービスを利用して投稿や保存、送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。
          </li>
          <li>
            「送信」とは、何らかの手段により本サービスを使用してコンテンツを生成する行為を含むものとします。ただし、本サービスを通じてコンテンツを生成する行為を「送信」と称さない場合や、これを表記しない場合もあります。
          </li>
          <li>
            「当方」とは、Transable著作者および運営を行っている者を意味します。
          </li>
          <li>
            「当方ウェブサイト」とは、当方が運営するウェブサイト（理由の如何を問わず、当方のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
          </li>
          <li>
            「非登録ユーザー」とは、別に定める本サービスの利用者としての登録を行わずに利用している個人または法人を意味します。「登録ユーザー」とは、別に定める本サービスの利用者としての登録を行なって利用している個人または法人を意味します。
          </li>
          <li>
            「本サービス」とは、当方が提供するすべてのサービスを意味します。
          </li>
        </ol>
        <h3>第3条（目的）</h3>
        <ol>
          <li>
            本サービスは、教育および研究利用を目的とし、教育機関、教師、学生、生徒、および研究者が教育活動および研究活動において利用することを想定しています。
          </li>
          <li>
            コンテンツを生成する機能は専門的助言を目的としたものではありません。資格のある専門家によって提供されるアドバイスに代わるものでもありません。
          </li>
          <li>本サービスの営利目的での利用は認められません。</li>
        </ol>
        <h3>第4条（本サービスの利用条件）</h3>
        非登録者は本サービスの利用に一部制限があります。本サービスを利用するにあたり、以下の条件を満たさない場合、当方は利用を拒否することができます。
        <ol>
          <li>
            未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていない場合。
          </li>
          <li>
            反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当方が判断した場合
          </li>
          <li>
            利用者が過去に当方との契約に違反した者またはその関係者であると当方が判断した場合。
          </li>
        </ol>
        <h3>第5条（禁止事項）</h3>
        非登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当方が判断する行為をしてはなりません。以下の各号に掲げる行為によって、当方、本サービスの他の利用者またはその他の第三者に損害が生じた場合、非登録ユーザーはすべての法的責任を負うものとし、当方、本サービスの他の利用者またはその他のおよび第三者に損害を与えないものとします。非登録ユーザーが未成年である場合、法定代理人がすべての法的責任を負うものとします。
        <ol>
          <li>法令に違反する行為または犯罪行為に関連する行為</li>
          <li>
            当方、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為。
          </li>
          <li>公序良俗に反する行為。</li>
          <li>
            当方、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為。
          </li>
          <li>
            未成年の利用。ただし、本サービスが出力するコンテンツの確認と正誤の検証を行える能力を有する者（以下、「監督者」と言います。例えば、教員を含む教育機関に従事する個人や法人など。）の監督のもと利用する場合はその限りではありません。このとき、他の禁止事項を遵守してください。本サービスの利用によって生じた取引、連絡、紛争等については監督者の責任によって解決してください。
          </li>
          <li>
            本サービスを通じ、以下に該当し、または該当すると当方が判断する情報を当方または本サービスの他の利用者に送信すること。ここで送信するとは、本サービスを使用してコンテンツを生成する行為も含みます。
          </li>
          <ol>
            <li>本人またはその他の第三者の個人情報</li>
            <li>
              当方、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報
            </li>
            <li>過度に暴力的または残虐な表現を含む情報</li>
            <li>
              コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報
            </li>
            <li>過度にわいせつな表現を含む情報</li>
            <li>差別を助長する表現を含む情報</li>
            <li>自殺、自傷行為を助長する表現を含む情報</li>
            <li>薬物の不適切な利用を助長する表現を含む情報</li>
            <li>反社会的な表現を含む情報</li>
            <li>チェーンメール等の第三者への情報の拡散を求める情報</li>
            <li>他人に不快感を与える表現を含む情報</li>
          </ol>
          <li>
            本サービスのネットワークまたはシステム等に過度な負荷をかける行為
          </li>
          <li>
            当方が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為
          </li>
          <li>本サービスの運営を妨害するおそれのある行為</li>
          <li>当方のネットワークまたはシステム等への不正アクセス</li>
          <li>第三者に成りすます行為</li>
          <li>本サービスの他の利用者のIDまたはパスワードを利用する行為</li>
          <li>
            当方が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
          </li>
          <li>本サービスの他の利用者の情報の収集</li>
          <li>
            当方、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
          </li>
          <li>
            当方ウェブサイト上で掲載する本サービス利用に関するルールに抵触する行為
          </li>
          <li>反社会的勢力等への利益供与</li>
          <li>面識のない異性との出会いを目的とした行為</li>
          <li>前各号の行為を直接または間接に惹起し、または容易にする行為</li>
          <li>前各号の行為を試みること</li>
          <li>その他、当方が不適切と判断する行為</li>
        </ol>
        <h3>第6条（本サービスの停止等）</h3>
        当方は、以下のいずれかに該当する場合には、非登録ユーザーに事前に通知することなく、
        本サービスの全部または一部の提供を停止または中断することができるものとします。
        この場合において、非登録ユーザーに損害が生じたとしても、当方は一切の責任を負いません。
        <ol>
          <li>
            本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合
          </li>
          <li>
            コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
          </li>
          <li>
            地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
          </li>
          <li>その他、当方が停止または中断を必要と判断した場合</li>
        </ol>
        <h3>第7条（権利帰属）</h3>
        <ol>
          <li>
            当方ウェブサイト及び本サービスに関する知的財産権は全て当方または当方にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当方ウェブサイトまたは本サービスに関する当方または当方にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
          </li>
          <li>
            非登録ユーザーは、当方が事前に書面によって承諾した場合を除き、その手段を問わず、本サービス上のコンテンツの転載、複製、加工その他の著作権侵害行為をしてはなりません。
          </li>
          <li>
            非登録ユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当方に対し表明し、保証するものとします。
          </li>
          <li>
            非登録ユーザーは、投稿データについて、当方に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、本サービスの他の利用者に対しても、本サービスを利用して非登録ユーザーが投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。
          </li>
          <li>
            非登録ユーザーは、当方及び当方から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。
          </li>
        </ol>
        <h3>第8条（本サービスの内容の変更、終了）</h3>
        当方は、当方の都合により、本サービスの内容を変更し、または提供を終了することができます。この場合において、
        非登録ユーザーに損害が生じたとしても、当方は一切の責任を負いません。
        <h3>第9条（保証の否認及び免責）</h3>
        <ol>
          <li>
            当方は、本サービスが非登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、非登録ユーザーによる本サービスの利用が非登録ユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
          </li>
          <li>
            当方は、本サービスに関して非登録ユーザーが被った損害、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。
          </li>
          <li>
            本サービスまたは当方ウェブサイトに関連して非登録ユーザーと本サービスの他の利用者または第三者との間において生じた取引、連絡、紛争等については、非登録ユーザーが自己の責任によって解決するものとします。
          </li>
        </ol>
        <h3>第10条（秘密保持）</h3>
        非登録ユーザーは、本サービスに関連して当方が非登録ユーザーや登録ユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当方の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
        <h3>第11条（利用者情報の取扱い）</h3>
        <ol>
          <li>
            当方による非登録ユーザーの利用者情報の取扱いについては、別途当方プライバシーポリシーの定めによるものとし、非登録ユーザーはこのプライバシーポリシーに従って当方が非登録ユーザーの利用者情報を取扱うことについて同意するものとします。
            当方は、非登録ユーザーが当方に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当方の裁量で、利用及び公開することができるものとし、非登録ユーザーはこれに異議を唱えないものとします。
          </li>
          <li>
            当社は、不正アクセス対策などのセキュリティ対応を目的とし、ユーザーのアクセス元IPアドレスの情報を収集します。
          </li>
        </ol>
        <h3>第12条（本規約等の変更）</h3>
        当方は、非登録ユーザーの同意を得ることなく、本規約の内容を追加、変更、または削除（以下「変更等」という。）することができます。本規約を変更する場合、変更後の本規約の施行時期及び内容を当方ウェブサイト上での掲示その他の適切な方法により周知し、
        または非登録ユーザーに通知します。但し、法令上非登録ユーザーの同意が必要となるような内容の変更等の場合は、当方所定の方法で非登録ユーザーの同意を得るものとします。
        <h3>第13条（連絡／通知）</h3>
        <ol>
          <li>
            本サービスに関する問い合わせその他非登録ユーザーから当方に対する連絡または通知、
            及び本規約の変更に関する通知その他当方から非登録ユーザーに対する連絡または通知は、当方の定める方法で行うものとします。
          </li>
        </ol>
        <h3>第14条（サービス利用契約上の地位の譲渡等）</h3>
        <ol>
          <li>
            非登録ユーザーは、当方の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
          </li>
          <li>
            当方は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに非登録ユーザーの情報を当該事業譲渡の譲受人に譲渡することができるものとし、
            非登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
          </li>
        </ol>
        <h3>第15条（分離可能性）</h3>
        本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、
        本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
        <h3>第16条（準拠法及び管轄裁判所）</h3>
        本規約及びサービス利用契約の準拠法は日本法とします。本規約またはサービス利用契約に起因し、または関連する一切の紛争については、京都地方裁判所を第一審の専属的合意管轄裁判所とします。
      </p>
      <br />
      <p>最終改定：2024年7月29日</p>
    </Container>
  );
}

export default UserPolicyFree;
