import { Pagination, Stack } from "@mui/material";


export function PaginationControl({ total, currentPage, handlePageChange , perpage }) {
    return <>
        <Stack spacing={2} sx={{ margin: "10px", float: "right" }}>
            <Pagination 
                count={Math.ceil(total / perpage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
             />
        </Stack>
    </>
    
}