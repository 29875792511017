export const Q6 = [
  {
    role: "system",
    content: process.env.REACT_APP_ChatGPTSetting_system_6,
  },
  {
    role: "assistant",
    content: process.env.REACT_APP_ChatGPTSetting_user_6,
  },
];
