import React from "react";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import EmailIcon from "@mui/icons-material/Mail";

const ContactButton = () => {
  const buttonStyles = {
    position: "fixed",
    right: "26px",
    bottom: "26px",
    zIndex: "999",
    borderRadius: "50%",
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <BootstrapTooltip
      title="問い合わせフォーム：要望・不具合はこちら"
      placement="top-end"
      style={buttonStyles}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="1px solid black"
        borderRadius="50%"
        width={26}
        height={26}
      >
        <IconButton
          size="small"
          href="https://forms.gle/FctHAwzQBiRtVcrJ9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <EmailIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>
    </BootstrapTooltip>
  );
};

export default ContactButton;
