// TopANews.jsに掲載するお知らせをここで編集する// updatesData.js
export const updates = [
  {
    date: "2024/7/29",
    title: "利用規約の一部変更のお知らせ 2",

    details: [
      {
        description:
          "2024/7/29 - 利用規約を変更しました。未成年の利用について明確にしました。詳しくは「禁止事項」の項をご参照ください。",

        links: [
          {
            text: "利用規約",
            url: "https://transable.net/userpolicy",
          },
        ],
      },

      // {
      //   description:
      //     "2024/6/25 - [質問内容]に質問が増えました。2種類の文章の違いを調べることができるようになりました。英語以外の言語も順次対応します。",

      //   // links: [
      //   //   {
      //   //     text: "不具合があった場合はこちら",
      //   //     url: "https://transable.notion.site/c6ca095d03f04a69b67560cfde30f37d",
      //   //   },
      //   // ],
      // },

      // {
      //   description:
      //     "2024/6/25 - OpenAI社のGPT-4oを導入しました。プロンプトは調整中です。（現在、比較的陽気なAIになっています。）",

      //   links: [
      //     {
      //       text: "不具合があった場合はこちら",
      //       url: "https://transable.notion.site/c6ca095d03f04a69b67560cfde30f37d",
      //     },
      //   ],
      // },

      {
        description:
          "2024/6/19 - Grammarlyのような校正ツール機能の開発状況について：",
        links: [
          {
            text: "校正ツール機能の開発状況",
            url: "https://transable.notion.site/Transable-d346871d23384670ababa0687509fe07",
          },
        ],
      },
    ],
  },
];
