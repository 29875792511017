import React, { useState, useEffect } from "react";
import { Box, Button, InputAdornment, TextField } from "@mui/material";

import { APIGateWay, APILambda } from "../../constants/api";
import { getToken, authenticated } from "../../utils/amplify";

import TranslateSharpIcon from "@mui/icons-material/Autorenew";

export default function App({
  setEnglishText,
  setJapanesTranslation,
  updatteValue = null,
  setSaved,
}) {
  const [english, handleCountWord] = useState("");
  const [wordLength, setwordLength] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [maxChars, setMaxChars] = useState("");

  useEffect(() => {
    handleCountWord(updatteValue);
    setSaved(false);
  }, [updatteValue]);

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = await authenticated();
      const newMaxChars = isAuthenticated ? 1500 : 300;
      setMaxChars(newMaxChars);
    };
    checkAuthentication();

    //== Word count ==//
    //// caluclate
    function countWords(str) {
      if (str.trim() === "") {
        return 0;
      }
      return str.trim().split(/\s+/).length;
    }

    //// Inputted values
    const sentence = document.getElementById("EnglishC3").value;
    const wordCount = countWords(sentence);
    const wordLength = sentence.length;
    setwordLength(wordLength);
    setWordCount(wordCount);
    //== == ==//
    setEnglishText(english);
    setSaved(false);
  }, [english]);

  const handleEn2Ja = async () => {
    //// Loading state ////
    setLoading(true);

    ///////// Translate /////////
    const english = document.getElementById("EnglishC3").value;
    // Abort if no text is input
    if (!english || english.trim() === "") {
      document.getElementById("Japanese2_C3").value =
        "　⚠️ 英文エッセイが入力されていません";
      setLoading(false);
      return;
    }
    const isAuthenticated = await authenticated();
    const url = isAuthenticated
      ? APIGateWay.ziseDaiEigo
      : APILambda.ziseDaiEigo;

    const method = "POST";
    const headers = isAuthenticated ? { Authorization: await getToken() } : {};

    const submitBody = {
      detected_source_language: "EN",
      text: english,
      target_lang: "JA",
    };

    const body = JSON.stringify(submitBody);

    fetch(url, { method, headers, body })
      .then((res) => {
        setLoading(false); // Stop loading
        return res.json();
      })
      .then((resJson) => {
        document.getElementById("Japanese2_C3").value = resJson.text;
        setJapanesTranslation(resJson.text);
      })
      .catch((error) => {
        document.getElementById("Japanese2_C3").value =
          "⚠️エラーが発生しました";
        console.log(error);
      });
    setLoading(false);
  };

  return (
    <Box
      sx={{
        "& .MuiOutlinedInput-root": {
          borderTopRightRadius: { xs: 1, sm: 0, md: 1 },
        },
      }}
    >
      <TextField
        value={english}
        fullWidth
        multiline
        minRows={10}
        maxRows={20}
        inputProps={{
          style: { fontSize: 16, lineHeight: 1.5 },
          maxLength: maxChars,
        }}
        id="EnglishC3"
        label=""
        placeholder="英文エッセイを作成"
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderLeft: 1,
            borderLeftColor: "#c5c5c5",
            borderBottomRightRadius: 0,
          },
        }}
        onChange={(e) => handleCountWord(e.target.value)}
      />

      <Box sx={{ mt: 0, mb: 0, display: "flex", justifyContent: "right" }}>
        {/* word count */}
        <TextField
          id="word-count_enEssay"
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {wordLength}/{maxChars}文字
              </InputAdornment>
            ),
            sx: {
              fontSize: 13,
              maxWidth: "120px",
              height: "36.5px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
              borderRight: 0,
              borderTop: 0,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: 1,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: 0,
            },
          }}
        />

        <TextField
          id="word-count"
          label=""
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">{wordCount}単語</InputAdornment>
            ),
            sx: {
              fontSize: 13,
              maxWidth: { xs: "80px", sm: "90px", md: "110px" },
              height: "36.5px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderTop: 0,
              borderRight: 0,
              borderRadius: 0,
            },
            "& :hover .MuiOutlinedInput-notchedOutline": {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTop: 1,
              borderRight: 1,
            },
            "& :Mui-forcus .MuiOutlinedInput-notchedOutline": {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTop: 1,
              borderRight: 1,
            },
          }}
        />
        <Button
          variant="contained"
          disableElevation
          onClick={() => handleEn2Ja()}
          startIcon={<TranslateSharpIcon />}
          sx={{
            textTransform: "capitalize",
            m: 1,
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          disabled={loading}
        >
          翻訳
        </Button>
      </Box>
    </Box>
  );
}
