import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import titlelogo from "../assets/titlewithlogo.png";
import MiraiTranslateTM from "../assets/MiraiTranslate_Type-B.png";

import AccessToContactForm from "../AccessToContactForm";

export default function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexGrow: 1,
        marginTop: 10,
        paddingTop: 3,
        paddingBottom: 5,
        borderTop: 1,
        borderColor: "divider",
      }}
    >
      <Grid
        container
        rowSpacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "90%",
        }}
      >
        <Grid item xs={12}>
          <Box sx={{ height: "16px" }} /> {/* space */}
        </Grid>

        <Grid item xs={6}>
          <img src={titlelogo} alt="Transable" style={{ height: `24px` }}></img>
          <Typography variant="body2" display="block" sx={{ marginTop: 1 }}>
            本サービスの翻訳エンジンに
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              <a
                href="https://miraitranslate.com"
                target="_blank"
                rel="noreopener noreferrer"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={MiraiTranslateTM}
                  alt="MiraiTranslateTM"
                  style={{ height: "24px", marginRight: "8px" }}
                />
                <span style={{ fontSize: "12px" }}>
                  （運営会社：株式会社みらい翻訳）
                </span>
              </a>
            </Box>
            を使用しています。
          </Typography>

          <Typography variant="caption" display="block" sx={{ marginTop: 1 }}>
            また、本サービスは
            <a
              href="https://deepl.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              DeepL
            </a>
            および
            <a
              href="https://openai.com/blog/chatgpt"
              target="_blank"
              rel="noopener noreferrer"
            >
              ChatGPT
            </a>
            を使用しています。AIの情報が誤りの場合もあります。
          </Typography>
          <ul style={{ listStyle: "none", paddingLeft: "20px" }} sx={{}}>
            <li>
              <Typography variant="body2" sx={{ padding: 1 }}>
                <Link
                  component={RouterLink}
                  to="/"
                  color="inherit"
                  underline="hover"
                >
                  トップページ
                </Link>
              </Typography>
            </li>
            <li>
              <Typography variant="body2" sx={{ padding: 1 }}>
                <Link
                  component={RouterLink}
                  to="/userpolicy"
                  color="inherit"
                  underline="hover"
                >
                  利用規約
                </Link>
              </Typography>
            </li>
            <li>
              <Typography variant="body2" sx={{ padding: 1 }}>
                <Link
                  component={RouterLink}
                  to="/privacypolicy"
                  color="inherit"
                  underline="hover"
                >
                  プライバシーポリシー
                </Link>
              </Typography>
            </li>
            <li>
              <Typography variant="body2" sx={{ padding: 1 }}>
                <Link
                  component={RouterLink}
                  to="https://transable.notion.site/Transable-NEWS-f16a47fe482443e198cd4d5a83016dc2"
                  color="inherit"
                  underline="hover"
                >
                  Transable NEWS
                </Link>
              </Typography>
            </li>
            <li>
              <Typography variant="body2" sx={{ padding: 1 }}>
                <Link
                  component={RouterLink}
                  to="/login"
                  color="inherit"
                  underline="hover"
                >
                  アカウントの作成（教育機関限定）
                </Link>
              </Typography>
            </li>
            <li>
              <Typography variant="body2" sx={{ padding: 1 }}>
                <Link
                  component={RouterLink}
                  to="/salespolicies"
                  color="inherit"
                  underline="hover"
                >
                  特定商取引法に基づく表記
                </Link>
              </Typography>
            </li>
          </ul>
        </Grid>

        <Grid item xs={6}></Grid>

        <Grid
          item
          display={"flex"}
          alignContent={"center"}
          sx={{ marginTop: 5 }}
        >
          <Typography variant="body2">
            &copy; 2022 - {new Date().getFullYear()} Kohei Sugiyama / Produced
            by Kohei Sugiyama, supproted by{" "}
            <a
              href="https://studymeter.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Studymeter Inc
            </a>
            .
          </Typography>
        </Grid>
      </Grid>

      {/* Contact form */}
      <AccessToContactForm />
    </Box>
  );
}
