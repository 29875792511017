import * as React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {deleteById} from "../../services/transable"
import { StyledCode, StyledHeader } from '../../Mypage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '20px',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const questionTypeToText = (type) => {
  const types = {
    1: "パラグラフチェッカー(β)",
    2: "TOEFL iBT®",
    3: "GTEC",
    4: "CEFR",
    5: "IELTS",
  }

  return types[type]
}

export function ParagraphSupportModal({ open, handleClose, paragraph, setParagraphs, setLoading}) {
  const handleDeleteItem = async (id) => {
    const accept = window.confirm("この保存を削除してもよろしいですか？");
    if (accept) {
        setLoading(true)
        handleClose()
        const res = await deleteById(id)
        setParagraphs(res.data.data.paragraphs)
        setLoading(false)
    }
  }

  const handleReUseData = (paragraphReUseData) => {
    localStorage.setItem('paragraph', JSON.stringify(paragraphReUseData));
    localStorage.setItem('mode', "create");
    window.location.href = "/";
  }

  const handleUpdateData = (paragraphReUseData) => {
    localStorage.setItem('paragraph', JSON.stringify(paragraphReUseData));
    localStorage.setItem('mode', "update");
    window.location.href = "/";
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '65%', height: '85%', overflow: 'hidden' }}>
          <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 65,
                padding: '0 20px',
                backgroundColor: '#e0c059',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
          >
            <h2 component="h2" style={{ color: 'black' }}>PARAGRAPH SUPPORT</h2>
            <CloseIcon onClick={() => handleClose()} sx={{ fontSize: '34px', cursor: 'pointer' }} />
          </Box>
          <Box
            sx={{
              width: '100%',
              height: 'calc(100% - 65px)',
              margin: '65px 0 0 0',
              overflow: 'scroll',
              scrollbarWidth: 'none',
            }}
          >
            <Typography>最終保存日時 : {paragraph.updated_at}</Typography>
            <Box>
              <StyledHeader>短めの文章</StyledHeader>
              <StyledCode>{paragraph.japanese_short_sentences}</StyledCode>
            </Box>
            <Box>
                <StyledHeader> AIの提案</StyledHeader>
                <StyledCode>{paragraph.ai_suggestions}</StyledCode>
            </Box>
            <Box>
              <StyledHeader> 英文パラグラフ</StyledHeader>
              <StyledCode> {paragraph.english_text}</StyledCode>
            </Box>
            <Box>
              <StyledHeader> 日本語訳 </StyledHeader>
              <StyledCode> {paragraph.japanese_translation}</StyledCode>
            </Box>
            <Box>
              <StyledHeader> 評価内容 </StyledHeader>
              <Typography>
                  {questionTypeToText(paragraph.question_type)}: <br/>
                  <StyledCode>{paragraph.evaluation_details} </StyledCode>
              </Typography>
            </Box>
            <Box sx={{ padding: '30px 0 50px 0' }}>
              <Button onClick={() => handleReUseData(paragraph)} sx={{ marginRight: '5px' }} variant="outlined" disableElevation> 再利用する </Button>
              <Button onClick={() => handleUpdateData(paragraph)} sx={{ marginRight: '5px' }} variant="outlined" disableElevation> 編集する </Button>
              <Button onClick={() => handleDeleteItem(paragraph.transable_id)} sx={{ marginRight: '5px' }} variant="outlined" disableElevation> 削除する </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}   