export const Q5 = [
  {
    role: "system",
    content: process.env.REACT_APP_ChatGPTSetting_system_5,
  },
  {
    role: "assistant",
    content: process.env.REACT_APP_ChatGPTSetting_user_5,
  },
];
