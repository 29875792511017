export const Q8 = [
  {
    role: "system",
    content: process.env.REACT_APP_ChatGPTSetting_system_8,
  },
  {
    role: "assistant",
    content: process.env.REACT_APP_ChatGPTSetting_user_8,
  },
];
