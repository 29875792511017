import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Header from "./components/Header";
import Footer from "./components/Footer";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrolltoTop from "./components/ScrolltoTop";
import UserPolicy from "./components/UserPolicy/UserPolicyIndex";
import PrivacyPolicy from "./PrivacyPolicy";
import SalesPolicies from "./salespolicies";
// import AItextbook from "./components/AITextbook/AITextbook";
import Login from "./Login";
import AccountCreation from "./AccountCreation";
import Mypage from "./Mypage";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3B7EA1",
    },
    secondary: {
      main: "#ABA55A",
    },
  },
  typography: {
    fontFamily: "Noto Sans JP",
  },
});

Amplify.configure({
  aws_project_region: `${process.env.REACT_APP_Aws_Project_Region}`,
  aws_cognito_region: `${process.env.REACT_APP_Aws_Cognito_Region}`,
  // aws_user_pools_id: "us-east-1_BoyZ0x0Sz",
  aws_user_pools_id: `${process.env.REACT_APP_Aws_User_Pools_Id}`,
  // aws_user_pools_web_client_id: "4aa93b61n0eu67atpa2rl9oqoj",
  aws_user_pools_web_client_id: `${process.env.REACT_APP_Aws_User_Pools_Web_Client_Id}`,
  aws_mandatory_sign_in: `${process.env.REACT_APP_Aws_Mandatory_Sign_In}`,
});

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <Authenticator.Provider>
        <ThemeProvider theme={theme}>
          <Header />

          <ScrolltoTop />
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/userpolicy" element={<UserPolicy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/account-creation" element={<AccountCreation />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/salespolicies" element={<SalesPolicies />} />
            <Route path="/mypage" element={<Mypage />} />
            {/* <Route path="/nexttb" element={<AItextbook />} /> */}
          </Routes>

          <Footer />
        </ThemeProvider>
      </Authenticator.Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
