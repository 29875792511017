import React from "react";
import { Typography, Box, Link, Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";
import { styled } from "@mui/material/styles";
import { updates } from "./updatesData"; // 主なニュースはここで編集

// Custom styled components
const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  "&.Mui-expanded": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function TopANews() {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 4,
      }}
    >
      {updates.map((update, index) => (
        <Accordion
          key={index}
          elevation={1}
          square
          sx={{ width: "100%", maxWidth: 800, margin: "0 auto" }}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  color: "primary.contrastText",
                }}
              >
                {update.date}
              </Typography>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "primary.contrastText",
                  marginLeft: 2,
                  marginRight: 8,
                }}
              >
                {update.title}
              </Typography>
            </Box>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            {update.details.map((detail, idx) => (
              <Box key={idx} sx={{ marginTop: 1, marginBottom: 1 }}>
                <Typography sx={{ fontSize: 13, marginBottom: 1 }}>
                  {detail.description}
                </Typography>
                {detail.additionalInfo && (
                  <Box sx={{ marginBottom: 1, paddingLeft: 2 }}>
                    {detail.additionalInfo.map((info, infoIdx) => (
                      <Typography key={infoIdx} sx={{ fontSize: 13 }}>
                        • {info}
                      </Typography>
                    ))}
                  </Box>
                )}
                {detail.links && (
                  <Box sx={{ marginBottom: 1, paddingLeft: 2 }}>
                    {detail.links.map((link, linkIdx) => (
                      <Typography
                        key={linkIdx}
                        sx={{
                          fontSize: 13,
                          margin: "4px 0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="hover"
                          sx={{
                            color: "primary.main",
                            fontSize: 13,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          • {link.text}
                          <LaunchIcon sx={{ fontSize: 13, marginLeft: 0.5 }} />
                        </Link>
                      </Typography>
                    ))}
                  </Box>
                )}
                {idx < update.details.length - 1 && (
                  <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
                )}
              </Box>
            ))}
          </CustomAccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
}
