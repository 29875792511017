import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Button, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ReviewsIcon from "@mui/icons-material/Reviews";

import { APIGateWay, APILambda } from "../../constants/api";
import { getToken, authenticated } from "../../utils/amplify";

import { Q1 } from "./QusestionsPara/Q1";
import { Q5 } from "../Questions/Q5";
import { Q6 } from "../Questions/Q6";
import { Q7 } from "../Questions/Q7";
import { Q8 } from "../Questions/Q8";

let prompt = {};
let Prompt;
let q = {};
let QuestionList = {};

export default function Selectmenu({ questionType = null }) {
  //== Loading State //==
  const [loading, setLoading] = useState(false);
  //== End Loading State //==

  //== Selected questions ==//
  const [question, setQuestion] = React.useState("");

  React.useEffect(() => {
    setQuestion(questionType);
  }, [questionType]);

  const handleChange = (event) => {
    setQuestion(event.target.value);

    if (event.target.value === 2) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q5; //iBT
    } else if (event.target.value === 3) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q6; //GTEC
    } else if (event.target.value === 4) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q7; //CEFR
    } else if (event.target.value === 5) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q8; //IELTS
    } else if (event.target.value === 1) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q8; //パラグラフチェッカー
    } else {
      alert("Error! 評価方法の種類を選んでください");
      setLoading(false); // Stop loading
      document.getElementById("C3_essayEvaluation").value = "";
    }
  };
  //== == == ==//

  //== ChatGPT API ==//
  const handleChatGPT = async () => {
    //// Loading effect /////
    setLoading(true); // Loading
    //== == ==//

    document.getElementById("C3_essayEvaluation").value =
      "しばらくお待ちください...";

    if (question === 2) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q5;
    } else if (question === 3) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q6;
    } else if (question === 4) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q7;
    } else if (question === 5) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q8;
    } else if (question === 1) {
      prompt = document.getElementById("EnglishC3").value;
      q = Q1;
    } else {
      alert("Error! 評価方法の種類を選んでください");
      setLoading(false); // Stop loading
      document.getElementById("C3_essayEvaluation").value = "";
    }

    if (!prompt) {
      // prompt 変数が空白（または偽の値）の場合、非同期処理をキャンセル
      document.getElementById("C3_essayEvaluation").value =
        "⚠️何も入力されていません。";
      setLoading(false); // Stop loading
      return;
    }

    let API_ENDPOINT = APILambda.chatGpt;
    let headers = {};

    const isAuthenticated = await authenticated();

    if (isAuthenticated) {
      API_ENDPOINT = APIGateWay.chatGpt;
      headers = {
        Authorization: await getToken(),
      };
    }

    Prompt = [
      {
        role: "user",
        content: prompt,
      },
    ];
    QuestionList = q.concat(Prompt);

    const requestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: QuestionList,
    });

    fetch(API_ENDPOINT, {
      method: "POST",
      headers,
      body: requestBody,
    })
      .then((response) => {
        setLoading(false); // Stop loading
        if (!response.ok) {
          document.getElementById("C3_essayEvaluation").value =
            "⚠️エラーが発生しました";
          throw new Error("Network response was NOT ok");
        }
        return response.json();
      })
      .then((data) => {
        document.getElementById("C3_essayEvaluation").value =
          data.choices[0].message.content;
      })
      .catch((error) => {
        document.getElementById("C3_essayEvaluation").value =
          "⚠️時間がかかっています。もう一度お試しください。";
        console.error(error);
        setLoading(false); // Stop loading
      });
  };
  //== == == ==//

  return (
    <Grid container spacing={0} sx={{ paddingTop: 1 }}>
      <Grid
        item
        xs={12}
        md={8}
        sx={{ display: "flex", alignItems: "flex-end" }}
      >
        <FormControl size="small" fullWidth>
          <Select
            labelId="question-to-chatGPT-forParagraph-select-label"
            id="question-to-chatGPT-forParagraph-select"
            value={question}
            label=""
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              fontSize: 14,
              borderTopRightRadius: { md: 0, sm: 4, xs: 4 },
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 0,
            }}
          >
            <MenuItem disabled value="">
              <em>評価方法を選ぶ</em>
            </MenuItem>
            <MenuItem value={1}>パラグラフチェッカー(β)</MenuItem>
            <MenuItem value={2}>TOEFL iBT®</MenuItem>
            <MenuItem value={3}>GTEC</MenuItem>
            <MenuItem value={4}>CEFR</MenuItem>
            <MenuItem value={5}>IELTS</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ display: "flex", alignItems: "flex-end" }}
      >
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={() => handleChatGPT()}
          startIcon={<ChecklistIcon />}
          fullWidth
          sx={{
            paddingBlock: 0.75,
            fontSize: 14,
            textTransform: "capitalize",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: { md: 4, sm: 0, xs: 0 },
            borderBottomRightRadius: 0,
          }}
          disabled={loading}
        >
          {loading ? "評価中..." : "パラグラフを評価する"}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ position: "relative" }}>
          {question === 5 && (
            <div>
              <Tooltip
                title="IELTSのルーブリックを覚えたChatGPTによる評価精度について(外部サイト)"
                placement="right"
                // arrow
                onClick={() => {
                  window.open(
                    "https://mizumot.com/lablog/archives/1805",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <ReviewsIcon color="primary" />
              </Tooltip>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
