import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { TaskAlt } from '@mui/icons-material';

const AlertSnackbarContainer = styled('div')(({ top, right, left, bottom }) => ({
  position: 'fixed',top,right,left,bottom,
  zIndex: 9
}));

const AlertSnackBar = ({ open, setOpen, message, adjustment, anchorOrigin, severity = 'success' }) => {
  const { left,top, right, bottom } = adjustment || {};

  const handleClose = (event, reason) => {
    if (reason === 'clickaway')  return;
    setOpen(false);
  };

  return (
    <div>
      {open && (
        <AlertSnackbarContainer
          top={top}
          right={right}
          left={left}
          bottom={bottom}
        >
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={anchorOrigin}
            style={{ position: 'relative' }}
          >
            <Alert
              iconMapping={{
                success: <TaskAlt sx={{ fontSize: 20 }} />,
              }}
              onClose={handleClose}
              severity= {severity }
              variant="filled"
              sx={{ width: '100%', padding: '2px 16px', fontSize:'13px' }}
            >
              { message }
            </Alert>
          </Snackbar>
        </AlertSnackbarContainer>
      )}
    </div>
  );
};

export default AlertSnackBar;
