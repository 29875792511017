import React from "react";

import { Grid, TextField } from "@mui/material";
import SelectMenusAndButtonforParagprah from "./SelectMenusAndButtonforParagprah";

export default function App({ questionType }) {
  return (
    <Grid container mt={1}>
      <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 0, marginBottom: 0 }}>
        <SelectMenusAndButtonforParagprah questionType={questionType} />
        <TextField
          fullWidth
          multiline
          minRows={4}
          maxRows={9}
          InputProps={{
            style: { fontSize: 16, lineHeight: 1.5 },
            readOnly: true,
          }}
          id="C3_essayEvaluation"
          label=""
          placeholder="評価結果が表示されます"
          variant="outlined"
          sx={{
            background: "#f1f3f4",
            "& .MuiOutlinedInput-notchedOutline": {
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
            "& :hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#c5c5c5",
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: 1,
              borderColor: "#c5c5c5",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
