/**
 * API from APIGateWay
 */
export const APIGateWay = {
  ziseDaiEigo: `${process.env.REACT_APP_APIGateWay_ziseDaiEigo}`,
  spring: `${process.env.REACT_APP_APIGateWay_spring}`,
  chatGpt: `${process.env.REACT_APP_APIGateWay_chatGpt}`,
  transable: `${process.env.REACT_APP_APIGateWay_transable}`,
};

/**
 * API from lambda function
 */
export const APILambda = {
  ziseDaiEigo: `${process.env.REACT_APP_APILambda_ziseDaiEigo}`,
  spring: `${process.env.REACT_APP_APILambda_spring}`,
  chatGpt: `${process.env.REACT_APP_APILambda_chatGpt}`,
  textToSpeech: `${process.env.REACT_APP_APILambda_textToSpeech}`,
};
