import { getToken } from "./amplify";

export const fetchApi = async ({url, method, params = null}) => {
    const headers = {
        Authorization: 'Bearer ' + await getToken(),
    };
    let res;
    if (method === 'POST' || method === 'PUT') {
        res = await fetch(url, { method, headers, body: params });
    } else {
        res = await fetch(url, { method, headers });
    }
    let data = {}
    if (res.ok) {
        data = await res.json()
    }

    return { data }
}
