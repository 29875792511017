import { APIGateWay } from "../constants/api"
import { fetchApi } from "../utils/fetchApi";

export const getDataByUserEmail = async (user_email) => {
    const url = APIGateWay.transable + `?user_email=${user_email}`
    const method = "GET"
    const data = await fetchApi({url, method})

    return data
}

export const getDataById = async (id) => {
    const url = APIGateWay.transable + `?transable_id=${id}`
    const method = "GET"
    const data = await fetchApi({url, method})

    return data
}

export const save = async ({type, english_text, japanese_translation, user_email, evaluation_details, question_type, japanese_short_sentences = null, ai_suggestions = null}) => {
    const url = APIGateWay.transable;
    const method = "POST"
    const params = JSON.stringify({ type, english_text, japanese_translation, user_email, evaluation_details, question_type, japanese_short_sentences, ai_suggestions })
    const data = fetchApi({url, method, params})

    return data
}

export const update = async ({id, english_text, japanese_translation, evaluation_details, question_type, japanese_short_sentences = null, ai_suggestions = null}) => {
    const url = APIGateWay.transable + `?transable_id=${id}`
    const method = "PUT"
    const params = JSON.stringify({ english_text, japanese_translation, evaluation_details, question_type, japanese_short_sentences, ai_suggestions })
    const data = fetchApi({url, method, params})

    return data
}

export const deleteById = async (id) => {
    const url = APIGateWay.transable + `?transable_id=${id}`
    const method = "DELETE"
    const data = fetchApi({url, method})

    return data
}