import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";

import TranslationAndLLM from "./TranslationAndLLM";
import ALC from "./ALC";

const textStyle = { marginLeft: 0 };

export default function BodyTextbox0() {
  const theme = useTheme();

  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={textStyle}>
        表現を調べる
      </Typography>

      <Accordion
        sx={{
          padding: "0px",
          marginTop: "8px",
          [theme.breakpoints.down("sm")]: {
            marginTop: "4px",
          },
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            padding: "0px",
          }}
        >
          <div>
            <Typography variant="subtitle1" fontWeight="bold">
              英和・和英検索（β）
            </Typography>
            <div
              style={{
                padding: "0px",
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                  width: "90%",
                },
              }}
            >
              <img
                src="https://eow.alc.co.jp/content/js/eowbtn/eow_logobtn2.png"
                alt="英辞郎 on the WEB"
                style={{
                  display: "block",
                  marginBottom: "0px",
                  maxWidth: "100%",
                }}
              />
            </div>
            <Typography
              variant="caption"
              sx={{ padding: "0px", marginTop: "4px", display: "block" }}
            >
              英辞郎 on the WEB （※試験導入中）
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0px",
          }}
        >
          <ALC />
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ marginBottom: 1 }} />

      <Typography variant="subtitle1" fontWeight="bold">
        AI検索
      </Typography>
      <Typography variant="caption" sx={textStyle} margin={1}>
        別の言い回しや解説、文法について調べることができます。
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexGrow: 1,
          maxWidth: 800,
          margin: "0 auto",
        }}
      >
        <Grid xs={12} container display="flex">
          <TranslationAndLLM />
        </Grid>
      </Box>
    </>
  );
}
