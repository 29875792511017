import React, { useState } from "react";
import {
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Collapse,
  CircularProgress,
} from "@mui/material";
import TranslateSharpIcon from "@mui/icons-material/Autorenew";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ReactMarkdown from "react-markdown";

import { APIGateWay, APILambda } from "../../constants/api";
import { getToken, authenticated } from "../../utils/amplify";

const replacePlaceholders = (template, replacements) => {
  return template.replace(
    /\{\{(\w+)\}\}/g,
    (_, key) => replacements[key] || ""
  );
};

const TranslationAndLLM = () => {
  const [japaneseText, setJapaneseText] = useState("");
  const [englishText, setEnglishText] = useState("");
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [aiResponse, setAIResponse] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleTranslation = async () => {
    setLoading(true);
    const isAuthenticated = await authenticated();
    const url = isAuthenticated ? APIGateWay.spring : APILambda.spring;
    const headers = isAuthenticated ? { Authorization: await getToken() } : {};

    const submitBody = {
      detected_source_language: "JA",
      text: japaneseText,
      target_lang: "EN",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(submitBody),
      });
      const data = await response.json();
      setEnglishText(data.text);
    } catch (error) {
      setEnglishText("⚠️エラーが発生しました");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChatGPT = async () => {
    setChatLoading(true);
    setLoading(true);

    let q = [];
    switch (question) {
      case 1:
        if (!japaneseText || japaneseText.trim() === "") {
          setAIResponse("　⚠️日本文が入力されていません");
          setChatLoading(false);
          return;
        } else {
          q = [
            {
              role: "system",
              content: process.env.REACT_APP_ChatGPTSetting_system_1,
            },
            {
              role: "user",
              content: replacePlaceholders(
                process.env.REACT_APP_ChatGPTSetting_user_1,
                { japaneseText }
              ),
            },
          ];
        }
        break;
      case 2:
        if (!japaneseText || japaneseText.trim() === "") {
          setAIResponse("　⚠️日本文が入力されていません");
          setChatLoading(false);
          return;
        } else {
          q = [
            {
              role: "system",
              content: process.env.REACT_APP_ChatGPTSetting_system_2,
            },
            {
              role: "user",
              content: replacePlaceholders(
                process.env.REACT_APP_ChatGPTSetting_user_2,
                { japaneseText }
              ),
            },
          ];
        }
        break;
      case 3:
        if (!japaneseText || japaneseText.trim() === "") {
          setAIResponse("　⚠️日本文が入力されていません");
          setChatLoading(false);
          return;
        } else {
          q = [
            {
              role: "system",
              content: process.env.REACT_APP_ChatGPTSetting_system_3,
            },
            {
              role: "user",
              content: replacePlaceholders(
                process.env.REACT_APP_ChatGPTSetting_user_3,
                { japaneseText }
              ),
            },
          ];
        }
        break;
      case 4:
        if (!englishText || englishText.trim() === "") {
          setAIResponse("　⚠️英文が入力されていません");
          setChatLoading(false);
          return;
        } else {
          q = [
            {
              role: "system",
              content: process.env.REACT_APP_ChatGPTSetting_system_4,
            },
            {
              role: "user",
              content: replacePlaceholders(
                process.env.REACT_APP_ChatGPTSetting_user_4,
                { englishText }
              ),
            },
          ];
          break;
        }
      case 5:
        if (!englishText || englishText.trim() === "") {
          setAIResponse("　⚠️英文が入力されていません");
          setChatLoading(false);
          return;
        } else {
          q = [
            {
              role: "system",
              content: process.env.REACT_APP_ChatGPTSetting_system_9,
            },
            {
              role: "user",
              content: replacePlaceholders(
                process.env.REACT_APP_ChatGPTSetting_user_9,
                { englishText }
              ),
            },
          ];
        }
        break;
      case 6:
        if (!englishText || englishText.trim() === "") {
          setAIResponse("　⚠️英文が入力されていません");
          setChatLoading(false);
          return;
        } else {
          q = [
            {
              role: "system",
              content: process.env.REACT_APP_ChatGPTSetting_system_11,
            },
            {
              role: "user",
              content: replacePlaceholders(
                process.env.REACT_APP_ChatGPTSetting_user_11,
                { englishText }
              ),
            },
          ];
        }
        break;
      case 7:
        if (!japaneseText || japaneseText.trim() === "") {
          setAIResponse("　⚠️[テキストA]が入力されていません");
          setChatLoading(false);
          return;
        }
        if (!englishText || englishText.trim() === "") {
          setAIResponse("　⚠️[テキストB]が入力されていません");
          setChatLoading(false);
          return;
        } else {
          q = [
            {
              role: "system",
              content: process.env.REACT_APP_ChatGPTSetting_system_10,
            },
            {
              role: "user",
              content: replacePlaceholders(
                process.env.REACT_APP_ChatGPTSetting_user_10,
                { japaneseText, englishText }
              ),
            },
          ];
        }
        break;
      default:
        setAIResponse("⚠️ [質問内容]から質問を選んでください");
        setChatLoading(false);
        setLoading(false);
        return;
    }

    const API_ENDPOINT = (await authenticated())
      ? APIGateWay.chatGpt
      : APILambda.chatGpt;
    const headers = (await authenticated())
      ? { Authorization: await getToken() }
      : {};

    const requestBody = JSON.stringify({
      model: "gpt-4o",
      messages: q,
    });

    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers,
        body: requestBody,
      });

      if (!response.ok) {
        throw new Error("GPT-4o response was not ok");
      }

      const data = await response.json();
      setAIResponse(data.choices[0].message.content);
    } catch (error) {
      console.error("GPT-4o Error: ", error);

      // Try GPT-3.5 if GPT-4o fails
      try {
        const response = await fetch(API_ENDPOINT, {
          method: "POST",
          headers,
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: q,
          }),
        });

        if (!response.ok) {
          throw new Error("GPT-3.5 response was not ok");
        }

        const data = await response.json();
        setAIResponse(data.choices[0].message.content);
      } catch (error) {
        console.error("GPT-3.5 Error: ", error);
        setAIResponse("⚠️エラーが発生しました");
      }
    } finally {
      setLoading(false);
      setChatLoading(false);
    }

    if (window.gtag) {
      window.gtag("event", "select_question", {
        event_category: "Question_LLM",
        event_label: `Question_LLM ${question}`,
      });
    }
  };

  return (
    <Box width={"100%"}>
      <TextField
        fullWidth
        multiline
        minRows={5}
        inputProps={{
          style: { fontSize: 14, lineHeight: 1.5 },
          maxLength: 150,
        }}
        InputProps={{
          sx: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: question === 7 ? "#e0f7fa" : "",
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderColor: question === 7 ? "#42a5f5" : "",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderWidth: question === 7 ? "2px" : "",
            },
          },
        }}
        id="Japanese_C3sub"
        label=""
        placeholder={
          question === 7
            ? "[テキストA]（最大140文字）"
            : "日本語 （最大140文字）"
        }
        variant="outlined"
        value={japaneseText}
        onChange={(e) => setJapaneseText(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />

      <Collapse in={isFocused}>
        <Button
          variant="contained"
          onClick={handleTranslation}
          startIcon={<TranslateSharpIcon />}
          fullWidth
          sx={{
            textTransform: "capitalize",
            marginTop: 0,
            borderRadius: 0,
            transition: "max-height 0.5s ease",
          }}
          disabled={loading}
        >
          {loading ? "お待ちください..." : "翻訳"}
        </Button>
      </Collapse>

      <TextField
        multiline
        minRows={5}
        inputProps={{
          style: { fontSize: 14, lineHeight: 1.5 },
          maxLength: question === 7 ? 140 : undefined,
        }}
        InputProps={{
          sx: {
            backgroundColor: question === 7 ? "#ffebee" : "",
            borderColor: question === 7 ? "ef5350" : "",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderWidth: question === 7 ? "2px" : "",
            },
          },
        }}
        id="English_C3sub"
        label=""
        placeholder={
          question === 7
            ? "[テキストB]（最大140文字）"
            : "翻訳結果（英文入力欄）"
        }
        variant="outlined"
        fullWidth
        sx={{
          background: "#f1f3f4",
          "& .MuiOutlinedInput-notchedOutline": {
            borderTop: question === 7 ? 1 : 0,
            borderBottom: question === 7 ? 1 : 0,
            borderRadius: 0,
            borderColor: question === 7 ? "red" : "",
          },
          "& :hover .MuiOutlinedInput-notchedOutline": {
            borderTop: question === 7 ? 1 : 1,
            borderBottom: question === 7 ? 1 : 1,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: 1,
            borderColor: question === 7 ? "red" : "",
          },
        }}
        value={englishText}
        onChange={(e) => setEnglishText(e.target.value)}
      />

      <FormControl size="small" fullWidth>
        <InputLabel id="QuestionsToChatGPT"></InputLabel>
        <Select
          labelId="question-to-chatGPT-select-label"
          id="question-to-chatGPT-select"
          value={question}
          label=""
          onChange={handleQuestionChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ fontSize: 14, borderRadius: 0, borderTop: 0 }}
        >
          <MenuItem disabled value="">
            <em>質問内容</em>
          </MenuItem>
          <MenuItem value={1}>日本文の英訳と解説</MenuItem>
          <MenuItem value={2}>日本文の英訳と基本5文型の分析</MenuItem>
          <MenuItem value={3}>日本文から複数の英語表現を出す</MenuItem>
          <MenuItem value={4}>[英文専用] 英文の修正はありますか</MenuItem>
          <MenuItem value={5}>[英文専用] 他の英語表現はありますか</MenuItem>
          <MenuItem value={6}>[英文専用] もっと平易な表現にする</MenuItem>
          <MenuItem value={7}>
            <span style={{ color: "#42a5f5" }}>[テキストA]</span>と
            <span style={{ color: "#ef5350" }}>[テキストB]</span>の違いは？
          </MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="contained"
        disableElevation
        onClick={handleChatGPT}
        startIcon={<TipsAndUpdatesIcon />}
        fullWidth
        sx={{ textTransform: "capitalize", marginTop: 0, borderRadius: 0 }}
        disabled={chatLoading}
      >
        {chatLoading ? <CircularProgress size={24} /> : "AIに尋ねる"}
      </Button>

      <Box
        sx={{
          margin: 0,
          background: "#f1f3f4",
          "& .MuiOutlinedInput-notchedOutline": {
            borderTop: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          "& :hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#c5c5c5",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: 1,
            borderColor: "#c5c5c5",
          },
          padding: 2,
          border: "1px solid #c5c5c5",
          borderRadius: "0 0 4px 4px",
          maxHeight: "400px",
          overflowY: "auto",
          fontSize: 14,
        }}
      >
        {aiResponse ? (
          <ReactMarkdown>{aiResponse}</ReactMarkdown>
        ) : (
          <Typography
            variant="body2"
            sx={{ color: "rgba(0, 0, 0, 0.3)", fontSize: 14 }}
          >
            AIによる解説が表示されます
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TranslationAndLLM;
