// translations/ja.js
const ja = {
  "Sign in": "ログイン",
  "Forgot your password?": "パスワードをお忘れの方",
  "Incorrect username or password.":
    "メールアドレス、ユーザー名またはパスワードが違います。",
  "Invalid verification code provided, please try again.":
    "指定された確認コードが無効です。もう一度お試しください。",
  "User does not exist.": "ユーザーは存在しません。",
  "Back to Sign In": "ログイン画面へ戻る",
  "Preferred Username": "ユーザー名",
  "Enter your Preferred Username": "ユーザー名を入力してください",
  "Cannot reset password for the user as there is no registered/verified email or phone_number":
    "登録済みまたは確認済みのメールアドレスまたは電話番号がないため、パスワードをリセットできません。",
  "Contact administrator to reset password.":
    "パスワードのリセットができないアカウントです。",
  "Email is not registered. Cannot reset password.":
    "メールアドレスが登録されていないため、パスワードをリセットできません。",
  "Username/client id combination not found.": "ユーザー名が見つかりません。",
  "Your passwords must match": "パスワードが一致していません。",
  "Password does not conform to policy: Password must have numeric characters":
    "パスワードには、1文字以上の数字を必ず入れてください。",
  "Password does not conform to policy: Password must have symbol characters":
    "パスワードには、1文字以上の記号を必ず入れてください。",
  "You are not allowed to reset your password because you have not registered your email address":
    "あなたは自分のメールアドレス登録がされていないため、パスワードリセットが許可されていません。",
  Username: "ユーザー名",
  "Enter your Username": "ユーザー名を入力してください",
  "Enter your username": "メールアドレスを入力してください", // Reset password
  Password: "パスワード",
  "Enter your Password": "パスワードを入力してください",
  "Attempt limit exceeded, please try after some time.":
    "短期間に多くのリセットがありました。恐れ入りますがしばらく時間を置いてから、再度お試しください。",
};

export default ja;
