import React, { useState, useEffect } from "react";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Button, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ReviewsIcon from "@mui/icons-material/Reviews";

import { APIGateWay, APILambda } from "../../constants/api";
import { getToken, authenticated } from "../../utils/amplify";

import { Q5 } from "../Questions/Q5";
import { Q6 } from "../Questions/Q6";
import { Q7 } from "../Questions/Q7";
import { Q8 } from "../Questions/Q8";

const QUESTION_TYPES = [
  { value: 1, label: "TOEFL iBT®", questions: Q5 },
  { value: 2, label: "GTEC", questions: Q6 },
  { value: 3, label: "CEFR", questions: Q7 },
  { value: 4, label: "IELTS", questions: Q8 },
];

export default function SelectMenu({ questionType = null }) {
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState(questionType || "");
  const [prompt, setPrompt] = useState("");

  useEffect(() => {
    setQuestion(questionType);
  }, [questionType]);

  const handleChange = (event) => {
    const selectedQuestion = QUESTION_TYPES.find(
      (q) => q.value === event.target.value
    );
    setQuestion(selectedQuestion.value);
    setPrompt(document.getElementById("EnglishC3").value);
  };

  const handleChatGPT = async () => {
    setLoading(true);
    document.getElementById("C3_essayEvaluation").value =
      "しばらくお待ちください...";

    const selectedQuestion = QUESTION_TYPES.find((q) => q.value === question);
    if (!selectedQuestion) {
      setLoading(false);
      document.getElementById("C3_essayEvaluation").value =
        "　⚠️ 評価方法の種類を選んでください";
      return;
    }

    if (!prompt) {
      document.getElementById("C3_essayEvaluation").value =
        "　⚠️ 何も入力されていません。";
      setLoading(false);
      return;
    }

    let API_ENDPOINT = APILambda.chatGpt;
    let headers = {};

    if (await authenticated()) {
      API_ENDPOINT = APIGateWay.chatGpt;
      headers = {
        Authorization: "Bearer " + (await getToken()),
      };
    }

    const requestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [
        ...selectedQuestion.questions,
        { role: "user", content: prompt },
      ],
    });

    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers,
        body: requestBody,
      });
      if (!response.ok) {
        throw new Error("Network response was NOT ok");
      }
      const data = await response.json();
      document.getElementById("C3_essayEvaluation").value =
        data.choices[0].message.content;
    } catch (error) {
      document.getElementById("C3_essayEvaluation").value =
        "　⚠️ エラーが発生しました";
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container sx={{ paddingTop: 1 }}>
      <Grid
        item
        sx={{ display: "flex", alignItems: "flex-end" }}
        xs={4}
        sm={4}
        md={5}
      >
        <FormControl size="small" fullWidth>
          <Select
            labelId="question-to-chatGPT-forEssay-select-label"
            id="question-to-chatGPT-forEssay-select"
            value={question}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              fontSize: 14,
              borderTopRightRadius: { md: 0, sm: 0, xs: 0 },
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <MenuItem disabled value="">
              <em>評価方法を選ぶ</em>
            </MenuItem>
            {QUESTION_TYPES.map((q) => (
              <MenuItem key={q.value} value={q.value}>
                {q.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        sx={{ display: "flex", alignItems: "flex-end" }}
        xs={8}
        sm={6}
        md={7}
        fullWidth
      >
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={handleChatGPT}
          startIcon={<ChecklistIcon />}
          // fullWidth
          sx={{
            paddingBlock: 0.75,
            fontSize: 14,
            textTransform: "capitalize",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 0,
          }}
          disabled={loading}
        >
          {loading ? "評価中..." : "エッセイを評価する"}
        </Button>
        <div style={{ position: "relative" }}>
          {question === 4 && (
            <Tooltip
              title="IELTSのルーブリックを覚えたChatGPTによる評価精度について(外部サイト)"
              placement="right"
              onClick={() => {
                window.open(
                  "https://mizumot.com/lablog/archives/1805",
                  "_blank",
                  "noopener noreferrer"
                );
              }}
              style={{ cursor: "pointer" }}
            >
              <ReviewsIcon color="primary" />
            </Tooltip>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
