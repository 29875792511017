export const Q7 = [
  {
    role: "system",
    content: process.env.REACT_APP_ChatGPTSetting_system_7,
  },
  {
    role: "assistant",
    content: process.env.REACT_APP_ChatGPTSetting_user_7,
  },
];
