import React, { useState } from "react";
import { Box, TextField, Typography, Paper } from "@mui/material";

function UniqueWordCounter() {
  const [text, setText] = useState("");
  const [totalWords, setTotalWords] = useState(0);
  const [uniqueWords, setUniqueWords] = useState(0);

  // テキスト変更時の処理
  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);

    // 単語の分割、記号削除、異なり語数の計算
    const words = newText
      .trim()
      .toLowerCase()
      .split(/\s+/)
      .map((word) => word.replace(/[^\w]/g, "")); // 記号を削除

    const uniqueWordSet = new Set(words.filter((word) => word));

    setTotalWords(words.filter((word) => word).length);
    setUniqueWords(uniqueWordSet.size);
  };

  return (
    <Box sx={{ p: 3, maxWidth: 600, margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        異なり語数計算アプリ
      </Typography>
      <TextField
        label="テキストを入力"
        multiline
        rows={8}
        variant="outlined"
        fullWidth
        value={text}
        onChange={handleTextChange}
        sx={{ mb: 3 }}
      />

      <Paper sx={{ p: 2, textAlign: "center" }}>
        <Typography variant="h6">結果</Typography>
        <Typography variant="body1">総語数: {totalWords}</Typography>
        <Typography variant="body1">異なり語数: {uniqueWords}</Typography>
      </Paper>
    </Box>
  );
}

export default UniqueWordCounter;
