import React from "react";
import {
  Typography,
  Link,
  Card,
  CardContent,
  Paper,
  Grid,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SchoolIcon from "@mui/icons-material/School";

function AccountCreation() {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <Link
        component={RouterLink}
        to="/"
        style={{ textDecoration: "underline" }}
      >
        <span style={{ fontSize: "16px" }}>&#x2190; トップページに戻る</span>
      </Link>
      <Typography variant="h5" gutterBottom style={{ marginTop: "50px" }}>
        （教育機関利用限定）個人用アカウント作成
      </Typography>
      <Typography variant="body1" style={{ marginTop: "20px" }}>
        下記から該当する方を選んで、アカウントを作成してください。
      </Typography>
      <Typography variant="body2" style={{ marginTop: "20px" }}>
        アカウント発行には数日を要します。発行完了はメールでお知らせしますので迷惑メールBOXに入っていないかご確認ください。
        <br />
        当面は無料でご利用いただけます。有料になる時はあらためて連絡いたします。
      </Typography>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        style={{ marginTop: "20px", padding: "0 20px" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            style={{ padding: "20px", backgroundColor: "#f5f5f5" }}
          >
            <Card>
              <CardContent>
                <SchoolIcon
                  color="primary"
                  style={{ fontSize: "40px", marginBottom: "10px" }}
                />
                <Typography variant="h6" gutterBottom>
                  すでに教育機関で <br />
                  利用している 先生・生徒
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  これまでに"xxx@transable.net"のメールアドレスでログインしたことがある先生および生徒が対象です。ご自身のメールアドレスでログインできるようになります。
                </Typography>
                <Link
                  component={RouterLink}
                  to="https://form.jotform.com/241150331849250"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="button"
                  style={{
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#3B7EA1",
                    color: "#FFF",
                    borderRadius: "4px",
                    textDecoration: "none",
                    marginTop: "10px",
                  }}
                >
                  自分のアカウントを作る
                </Link>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            style={{ padding: "20px", backgroundColor: "#f5f5f5" }}
          >
            <Card>
              <CardContent>
                <PersonAddIcon
                  style={{
                    fontSize: "40px",
                    marginBottom: "10px",
                    color: "#EDC33F",
                  }}
                />
                <Typography variant="h6" gutterBottom>
                  これから教育機関で利用したい先生 <br />
                  （教員経由のみ）
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  これから教育機関で利用したい先生はこちらからアカウントを作成してください。新規アカウントの登録ができます。生徒個人の登録については2024年7月にお知らせいたします。
                </Typography>
                <Link
                  component={RouterLink}
                  to="https://form.jotform.com/241519206790255"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="button"
                  style={{
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#EDC33F",
                    color: "#000",
                    borderRadius: "4px",
                    textDecoration: "none",
                    marginTop: "10px",
                  }}
                >
                  新規に利用開始する
                </Link>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AccountCreation;
