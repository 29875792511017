import React from "react";
import { Typography, Button, Box, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import BodyTextbox1C3 from "./EssayJapaneseandEnglishTextbox";
import RightSide from "../ConsiderExpressions";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { save, update } from "../../services/transable";
import BodyTextbox1C3Essay from "./BodyTextbox1C3_essay";
import AlertSnackBar from "../SnackBar/AlertSnackBar";
import LoadingButton from "@mui/lab/LoadingButton";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const textStyle = { marginLeft: 0 };

const description = `
  文法の校正チェックと日本語への翻訳を行いながら英文を記述します。
  ⚠️ログイン/ログアウトのタイミングで入力データが消えますのでご注意ください。
`;

export default function App({ mode = "create", essay = null, setSaved }) {
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);
  //ESSAY SUPPORT
  const [essaySupportInpput, setEssaySupportInpput] = React.useState("");
  const [essaySupportOutput, setEssaySupportOutput] = React.useState("");
  const [updatedTime, setUpdatedTime] = React.useState("");
  const [validateText, setValidateText] = React.useState("");
  const [transableId, setTransableId] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [alertSnackbar, setAlertSnackbar] = React.useState({
    open: false,
    severity: "",
    message: "",
  });
  const [questionType, setQuestionType] = React.useState("");
  const [isUpdate, setIsUpdate] = React.useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  React.useEffect(() => {
    if (essay && mode === "create") {
      setEssaySupportInpput(essay.english_text);
    } else if (essay && mode === "update") {
      setUpdatedTime(essay.updated_at + " 保存しました");
      setEssaySupportInpput(essay.english_text);
      setTransableId(essay.transable_id);
      setQuestionType(essay.question_type);
    }
  }, [mode, essay]);

  const handleCallApi = async (type) => {
    const evaluation_details =
      document.getElementById("C3_essayEvaluation").value;
    const question_type = document.getElementById(
      "question-to-chatGPT-forEssay-select"
    ).nextElementSibling.value;

    if (!essaySupportInpput || essaySupportInpput.trim() === "") {
      setValidateText(
        "[英文エッセイを作成] フィールドにはまだデータがありません!"
      );
      return;
    }
    // if (essaySupportOutput.trim() === "") {
    //   setValidateText("[翻訳された日本文が表示されます] フィールドにはまだデータがありません!")
    //   return;
    // }

    setValidateText("");
    const params = {
      type: "essay",
      english_text: essaySupportInpput,
      japanese_translation: essaySupportOutput,
      user_email: user?.attributes.email,
      evaluation_details,
      question_type: Number(question_type || 1),
    };

    handleCloseModal();

    try {
      setIsUpdate(true);
      if (type === "create") {
        const res = await save(params);
        setUpdatedTime(res.data.time_updated);
        setTransableId(res.data.transable_id);
        setSaved(true);
      } else {
        const res = await update({ ...params, id: transableId });
        setUpdatedTime(res.data.time_updated);
        setTransableId(res.data.transable_id);
        setSaved(true);
      }

      setAlertSnackbar({
        ...alertSnackbar,
        open: true,
        severity: "success",
        message: "保存しました",
      });
    } catch (err) {
      setAlertSnackbar({
        ...alertSnackbar,
        open: true,
        severity: "error",
        message: "保存できませんでした",
      });
    } finally {
      setIsUpdate(false);
    }
  };

  const handleSave = async () => {
    if (transableId === "") {
      await handleCallApi("create");
    } else {
      handleOpenModal();
    }
  };

  return (
    <Grid container spacing={{ xs: 1, md: 1 }}>
      <AlertSnackBar
        {...alertSnackbar}
        setOpen={(open) => setAlertSnackbar({ ...alertSnackbar, open })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        adjustment={{ top: "54px", right: "0px" }}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            以前に保存したことがある内容ですが、どのような保存を行いますか？
          </Typography>
          <br />
          <Box>
            <Button
              onClick={() => {
                handleCallApi("create");
              }}
              variant="contained"
              sx={{ marginRight: "5px" }}
            >
              新規に保存する
            </Button>
            <Button
              onClick={() => {
                handleCallApi("update");
              }}
              variant="outlined"
            >
              上書きする
            </Button>
          </Box>
        </Box>
      </Modal>
      <Grid item xs={12} sm={9} md={9}>
        <Typography variant="h5" fontWeight="bold" sx={textStyle}>
          エッセイを書く
        </Typography>
        <Typography variant="caption" sx={textStyle}>
          {description}
        </Typography>

        <Grid container columns={{ xs: 6, sm: 8, md: 12 }} spacing={0}>
          <Grid item xs={12} sm={12} md={6}>
            <BodyTextbox1C3
              updatteValue={essay?.english_text}
              setJapanesTranslation={setEssaySupportOutput}
              setEnglishText={setEssaySupportInpput}
              setSaved={setSaved}
            />
          </Grid>

          {/* Translated Japanese */}
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              multiline
              minRows={10}
              maxRows={20}
              id="Japanese2_C3"
              label=""
              placeholder="翻訳された日本文が表示されます"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                },
                "& :hover .MuiOutlinedInput-notchedOutline": {},
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {},
              }}
              InputProps={{
                style: { background: "#f1f3f4", fontSize: 16, lineHeight: 1.5 },
              }}
            />
          </Grid>

          {/* evaluation  */}
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight="bold" sx={{ marginTop: 1 }}>
              評価する
            </Typography>
            <Typography variant="caption" sx={textStyle}>
              記述した英文に対して、様々なテストの評価基準に照らし合わせたフィードバックを行います。
            </Typography>
            <BodyTextbox1C3Essay questionType={questionType} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        {authStatus === "authenticated" ? (
          <Box sx={{ marginBottom: "10px" }}>
            <Typography
              sx={{ marginBottom: "5px", fontSize: "12px", color: "red" }}
            >
              {validateText}
            </Typography>
            <LoadingButton
              onClick={handleSave}
              sx={{ width: "100%", marginBottom: "8px" }}
              variant="contained"
              loading={isUpdate}
              disableElevation
            >
              <span>保存する</span>
            </LoadingButton>
            <Typography
              variant="body2"
              sx={{
                color: "text.primary",
                fontSize: 14,
                display: "flex",
                justifyContent: "end",
              }}
            >
              {updatedTime}
            </Typography>
          </Box>
        ) : null}
        <RightSide />
      </Grid>
    </Grid>
  );
}
