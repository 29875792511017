import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import LanguageSupports from "./ClassicIndex";
import EssaySupport from "./EssaySupport/Classic3"; // Essay
import ParagraphSupport from "./ParagraphSupport/ClassicParagraph"; // Paragraph
import Toolkit from "./Toolkit"; // Toolkit
// import ConsideringMyOpinions_CMO from "./ConsideringMyOpinions_CMO/indexCMO";

import { useAuthenticator } from "@aws-amplify/ui-react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [essay, setEssay] = useState(null);
  const [paragraph, setParagraph] = useState(null);
  const [mode, setMode] = useState("create"); // create | edit
  const { user } = useAuthenticator((context) => [context.user]);
  const [saved, setSaved] = useState(false);

  const updateSaved = (value) => {
    localStorage.setItem("saved", value);
    setSaved(value);
  };

  useEffect(() => {
    try {
      const essayFromLocal = localStorage.getItem("essay");
      const paragraphFromLocal = localStorage.getItem("paragraph");
      const modeFromLocal = localStorage.getItem("mode");

      setMode(modeFromLocal || "create");

      if (essayFromLocal) {
        setEssay(JSON.parse(essayFromLocal));
        setTimeout(() => {
          localStorage.removeItem("essay");
        }, 200);
        setValue(1);
      }

      if (paragraphFromLocal) {
        setParagraph(JSON.parse(paragraphFromLocal));
        setTimeout(() => {
          localStorage.removeItem("paragraph");
        }, 200);
        setValue(2);
      }
    } catch (error) {
      console.error("Error parsing local storage data", error);
    }
  }, []);

  const handleChange = useCallback(
    (event, newValue) => {
      const input = document.getElementById("EnglishC3");
      const inputJapanese = document.querySelector(
        "#paragraph-support-container #Japanese"
      );
      const text = input?.value || "";
      const textJapanese = inputJapanese?.value || "";

      if (value === 0) {
        setValue(newValue);
        return;
      }

      if (!saved && user && (text.length > 0 || textJapanese.length > 0)) {
        const userConfirmed = window.confirm("保存せずに終了しますか?");
        if (userConfirmed) {
          setValue(newValue);
        }
      } else {
        setValue(newValue);
      }
    },
    [saved, user, value]
  );

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          background: "#f8f9fa",
        }}
        flexGrow={1}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tabs
          value={value}
          aria-label="tabs"
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Classic" {...a11yProps(0)} sx={{ flexGrow: 1 }} />
          <Tab label="Essay Support" {...a11yProps(1)} sx={{ flexGrow: 1 }} />
          <Tab
            label="Paragraph Support(β)"
            {...a11yProps(2)}
            sx={{ flexGrow: 1, textTransform: "none" }}
          />
          <Tab
            label="Toolkit(β)"
            {...a11yProps(3)}
            sx={{ flexGrow: 1, textTransform: "none" }}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <LanguageSupports />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EssaySupport mode={mode} essay={essay} setSaved={updateSaved} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ParagraphSupport
          mode={mode}
          paragraph={paragraph}
          setSaved={updateSaved}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Toolkit />
      </TabPanel>
    </>
  );
}
