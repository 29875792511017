import { I18n } from "aws-amplify";
import ja from "./translations/ja";
import {
  Authenticator,
  translations,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Navigate } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./Login.css";
import { Box, Link, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import AccountCreationForLoginPage from "./AccountCreationForLoginPage";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");
I18n.putVocabularies({
  ja,
});

const formFields = {
  signIn: {
    username: {
      placeholder: "メールアドレス または ユーザーID",
      isRequired: true,
      label: "ユーザー名",
    },
    password: {
      isRequired: true,
    },
  },
};

function Login() {
  const { authStatus, error } = useAuthenticator((context) => [
    context.authStatus,
    context.error,
  ]);

  const getCustomErrorMessage = (error) => {
    if (!error) return null;

    switch (error.message) {
      case "Cannot reset password for the user as there is no registered/verified email or phone_number":
        return "登録済みまたは確認済みのメールアドレスまたは電話番号がないため、パスワードをリセットできません。";
      case "Email is not registered. Cannot reset password.":
        return "メールアドレスが登録されていないため、パスワードをリセットできません。";
      default:
        return error.message;
    }
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        margin: 10,
        marginX: "auto",
        maxWidth: "620px",
        border: "1px solid #d7d7d7",
        padding: "40px 0",
        boxShadow: "2px 4px 6px #d7d7d7",
        position: "relative",
        borderRadius: "10px",
      }}
    >
      <Box padding={"10px"}>
        <Link component={RouterLink} to="/">
          <span className="closeButton">&times;</span>
        </Link>
        <Box sx={{ padding: 2, lineHeight: 1.6 }}>
          <Typography variant="body1" component="h2" gutterBottom>
            (2024/7/29 更新)
            <br />
            ログインシステムを改良しました。パスワードがリセットされている可能性がありますので、大変お手数をおかけしますが、
          </Typography>
          <Typography variant="body1" component="p" sx={{ color: "red" }}>
            ログインできない時は、下部にある「パスワードをお忘れの方」よりパスワードの再発行を行なってください。
          </Typography>
          <Typography variant="body1" component="p" mt={2}>
            不具合がある場合は、
            <Link
              href="https://forms.gle/FctHAwzQBiRtVcrJ9"
              sx={{ textDecoration: "underline", color: "primary.main" }}
            >
              問い合わせフォーム
            </Link>
            よりお知らせくださいませ。
          </Typography>
        </Box>
      </Box>
      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="TabsinLoginPage"
              centered
              variant="fullWidth"
            >
              <Tab
                label="ログイン"
                value="1"
                sx={{ fontWeight: "bold", fontSize: "16px" }}
              />
              <Tab
                label="アカウントの作成"
                value="2"
                sx={{ fontWeight: "bold", fontSize: "16px" }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Authenticator
              hideSignUp={true}
              formFields={formFields}
              loginMechanisms={["username", "email"]}
            >
              {({ signIn }) => {
                if (authStatus === "authenticated") {
                  return <Navigate to="/" />;
                } else if (error) {
                  return (
                    <div style={{ color: "red" }}>
                      {getCustomErrorMessage(error)}
                    </div>
                  );
                } else {
                  return signIn;
                }
              }}
            </Authenticator>
          </TabPanel>
          <TabPanel value="2">
            <AccountCreationForLoginPage />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default Login;
