import React from "react";
import { Container, Link } from "@mui/material";

export default function salespolicies() {
  return (
    <Container sx={{ flexGrow: 1 }}>
      <p>
        <h1>特定商取引法に基づく表記</h1>
        <h3>サービス名</h3>
        Transable
        <h3>連絡先</h3>
        お問い合わせは、下記のお問い合わせフォームよりお願いいたします。
        <ol>
          <li>
            <Link
              href="https://share.hsforms.com/1mrMdIAd_TjmFtqhjOwUKUg4qumx"
              color="inherit"
              underline="always"
              target="_blank"
              rel="noopener noreferrer"
            >
              Studymeter　お問い合わせフォーム
            </Link>
          </li>
          <li>
            <Link
              href="https://forms.gle/FctHAwzQBiRtVcrJ9"
              color="inherit"
              underline="always"
              target="_blank"
              rel="noopener noreferrer"
            >
              Transable　お問い合わせフォーム
            </Link>
          </li>
        </ol>
        <h3>販売価格・送料</h3>
        お客様の利用規模や計画によって異なります。詳細はお問い合わせフォームよりお問い合わせください。
        <br />
        なお、インターネットに接続するための通信料は別途ご負担ください。
        <h3>追加手数料</h3>
        サービス利用に必要なインターネット接続料金や通信料。振込手数料。
        <h3>代金のお支払い方法</h3>
        クレジットカードまたは国内の銀行振込
        <h3>代金の支払時期</h3>
        ご登録したクレジットカードの締め日や契約内容により異なります。ご登録されるカード会社までお問い合わせください。
        <br />
        国内の銀行振込の場合、10日以内にお支払いください。
        <h3>商品の引渡・提供時期</h3>
        購入手続きが完了次第、利用可能な状態となります。
        <h3>返品・キャンセルポリシー</h3>
        本サービスは、クーリング・オフ適用対象外です。
        <br />
        本サービスについては、購入手続完了後の返品またはキャンセルをお受けいたしません。
        <br />
        あらかじめ、お持ちの環境（パソコンやタブレットなど）でご利用いただけるかをご確認ください。
        <br />
        なお、商品・サービスに瑕疵がある場合は、利用規約の定めに従って対応します。
        <h3>代表者</h3>
        Kohei Sugiyama
        <h3>メールアドレス</h3>
        info[at]studymeter-web.com （[at]を@に変えてください） <br />
        メールアドレスのほか、上記の「連絡先」のお問い合わせフォームよりお問合せ可能です。
        <h3>所在地・電話番号</h3>
        請求があった場合、遅滞なく開示いたします。
      </p>
    </Container>
  );
}
