import React, { useState, useEffect, useRef } from "react";
import { Box, Button, InputAdornment, Typography, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import { APIGateWay, APILambda } from "../../constants/api";
import { getToken, authenticated } from "../../utils/amplify";

import ClearIcon from "@mui/icons-material/Clear";
import TranslateSharpIcon from "@mui/icons-material/Autorenew";

import BodyTextbox1Para from "./BodyTextbox1_Para";
import BodyTextbox1C3Paragraph from "./BodyTextbox1_Paragraph";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { save, update } from "../../services/transable";

// Import from "EssaySupport" !!!
import RightSide from "../ConsiderExpressions";
import AlertSnackBar from "../SnackBar/AlertSnackBar";

import { Q0 } from "./QusestionsPara/Q0";
let prompt = {};
let Prompt;
let q = {};
let QuestionList = {};

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function App(props) {
  //== Loading state ==//
  const [loading, setLoading] = useState(false);
  const [japanese, setInputValue] = useState("");
  const [maxChars, setMaxChars] = useState("");
  const [wordCount, setwordCount] = useState("");
  const [alertSnackbar, setAlertSnackbar] = React.useState({
    open: false,
    severity: "",
    message: "",
  });
  const [questionType, setQuestionType] = React.useState("");

  const [paragraphSupportInpput, setParagraphSupportInpput] =
    React.useState("");
  const [paragraphSupportOutput, setParagraphSupportOutput] =
    React.useState("");
  const [updatedTime, setUpdatedTime] = React.useState("");
  const [validateText, setValidateText] = React.useState("");
  const [transableId, setTransableId] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  React.useEffect(() => {
    const { mode, paragraph } = props;
    if (paragraph && mode === "create") {
      setParagraphSupportInpput(paragraph.english_text);
      setInputValue(paragraph.japanese_short_sentences);
    } else if (paragraph && mode === "update") {
      setUpdatedTime(paragraph.updated_at + " 保存しました");
      setParagraphSupportInpput(paragraph.english_text);
      setTransableId(paragraph.transable_id);
      setInputValue(paragraph.japanese_short_sentences);
      setQuestionType(paragraph.question_type);
    }
  }, [props]);

  //== Change text value ==//
  const handleChange = (event) => {
    const newJapanese = event.target.value;
    setInputValue(newJapanese);
  };
  //== End Change text value ==//

  const handleChatGPT = async () => {
    //// Loading effect /////
    setLoading(true);
    //// ///// /////

    document.getElementById("ChatGPT_answer").value =
      "しばらくお待ちください...";

    prompt = document.getElementById("Japanese").value;
    q = Q0;

    if (!prompt) {
      // prompt 変数が空白（または偽の値）の場合、非同期処理をキャンセル
      alert("何も入力されていません。");
      document.getElementById("ChatGPT_answer").value =
        "⚠️何も入力されていません。";
      setLoading(false); // Stop loading
      return;
    }

    let API_ENDPOINT = APILambda.chatGpt;
    let headers = {};

    const isAuthenticated = await authenticated();

    if (isAuthenticated) {
      API_ENDPOINT = APIGateWay.chatGpt;
      headers = {
        Authorization: await getToken(),
      };
    }

    Prompt = [
      {
        role: "user",
        content: prompt,
      },
    ];
    QuestionList = q.concat(Prompt);

    const requestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      temperature: 0.5,
      messages: QuestionList,
    });

    fetch(API_ENDPOINT, {
      method: "POST",
      headers,
      body: requestBody,
    })
      .then((response) => {
        setLoading(false); // Stop loading
        if (!response.ok) {
          document.getElementById("ChatGPT_answer").value =
            "⚠️エラーが発生しました";
          throw new Error("Network response was NOT ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        document.getElementById("ChatGPT_answer").value =
          data.choices[0].message.content;
      })
      .catch((error) => {
        document.getElementById("ChatGPT_answer").value =
          "⚠️特別なエラーが発生しました。再読み込みしてください。";
        console.error("Fetch error: ", error);
      });
    // //// Loading effect /////
    // setLoading(false);
    // //// ///// /////
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      const isAuthenticated = await authenticated();
      const newMaxChars = isAuthenticated ? 150 : 150;
      setMaxChars(newMaxChars);

      //== Word count ==//
      //// caluclate and inputted values
      const sentence = document.getElementById("Japanese").value;
      const wordCount = sentence.length;
      setwordCount(wordCount);
      //== == ==//

      const url = isAuthenticated
        ? APIGateWay.ziseDaiEigo
        : APILambda.ziseDaiEigo;
      const method = "POST";
      const headers = isAuthenticated
        ? { Authorization: await getToken() }
        : {};

      const submitBody = {
        detected_source_language: "JA",
        text: japanese,
        target_lang: "EN",
      };

      const body = JSON.stringify(submitBody);

      fetch(url, { method, headers, body })
        .then((res) => {
          return res.json();
        })
        .then((resJson) => {
          const englishElement = document.getElementById("English");
          if (englishElement) {
            englishElement.value = resJson.text;
          }
        })
        .catch((error) => {
          const englishElement = document.getElementById("English");
          if (englishElement) {
            englishElement.value = "⚠️エラーが発生しました";
          }
          console.log(error);
        });
    }, 550); // wait time.
    return () => clearTimeout(timer); // time-reset.
  }, [japanese, props]);

  //== Clear Text ==//
  const inputEl = useRef(null);
  const handleClearText = () => {
    setInputValue("");
    inputEl.current.focus();
  };
  //== == ==//

  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  const handleCallApi = async (type) => {
    const question_type = document.getElementById(
      "question-to-chatGPT-forParagraph-select"
    ).nextElementSibling.value;
    const evaluation_details =
      document.getElementById("C3_essayEvaluation").value;
    const AI_answer = document.getElementById("ChatGPT_answer").value;

    // if (japanese.trim() === "") {
    //   setValidateText(
    //     "[日本語から英語の文章を考える] フィールドにはまだデータがありません!"
    //   );
    //   return;
    // }

    setValidateText("");

    const params = {
      japanese: japanese || "",
      type: "paragraph",
      english_text: paragraphSupportInpput || "",
      japanese_translation: paragraphSupportOutput,
      user_email: user?.attributes.email,
      evaluation_details,
      question_type: Number(question_type || 1),
      japanese_short_sentences: japanese || "",
      ai_suggestions: AI_answer,
    };

    handleCloseModal();

    try {
      setIsUpdate(true);
      if (type === "create") {
        const res = await save(params);
        setUpdatedTime(res.data.time_updated);
        setTransableId(res.data.transable_id);
        props?.setSaved(true);
      } else {
        const res = await update({ ...params, id: transableId });
        setUpdatedTime(res.data.time_updated);
        setTransableId(res.data.transable_id);
        props?.setSaved(true);
      }

      setAlertSnackbar({
        ...alertSnackbar,
        open: true,
        severity: "success",
        message: "保存しました",
      });
    } catch (err) {
      setAlertSnackbar({
        ...alertSnackbar,
        open: true,
        severity: "error",
        message: "保存できませんでした",
      });
    } finally {
      setIsUpdate(false);
    }
  };

  const handleSave = async () => {
    if (transableId === "") {
      await handleCallApi("create");
    } else {
      handleOpenModal();
    }
  };

  return (
    <Grid container spacing={{ xs: 1, md: 2 }} id="paragraph-support-container">
      <AlertSnackBar
        {...alertSnackbar}
        setOpen={(open) => setAlertSnackbar({ ...alertSnackbar, open })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        adjustment={{ top: "54px", right: "0px" }}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            以前に保存したことがある内容ですが、どのような保存を行いますか？
          </Typography>
          <br />
          <Box>
            <Button
              onClick={() => {
                handleCallApi("create");
              }}
              variant="contained"
              sx={{ marginRight: "5px" }}
            >
              新規に保存する
            </Button>
            <Button
              onClick={() => {
                handleCallApi("update");
              }}
              variant="outlined"
            >
              上書き保存する
            </Button>
          </Box>
        </Box>
      </Modal>
      <Grid xs={12} sm={9} md={9}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="bold">
            日本語から英語の文章を考える
          </Typography>
          <Typography variant="caption">
            ここでは、AIのヒントをもとに英文を考えます。
            AIが複数の表現を表示します。
          </Typography>
          <Grid
            container
            columns={{ xs: 6, sm: 8, md: 12 }}
            sx={{ mt: 0, pt: 1, px: 0 }}
            spacing={0}
          >
            <Grid xs={6} sm={4} md={6}>
              <TextField
                inputRef={inputEl}
                value={japanese}
                fullWidth
                multiline
                minRows={6}
                maxRows={10}
                inputProps={{
                  style: { fontSize: 16, lineHeight: 1.5 },
                  maxLength: maxChars,
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
                id="Japanese"
                label=""
                placeholder="検討する日本語センテンス（短めの文章）を入力してください"
                variant="outlined"
                onChange={handleChange}
              ></TextField>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                {/* clear button */}
                <Button
                  variant="outlined"
                  disableElevation
                  sx={{
                    marginTop: 0,
                    borderTop: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderColor: "#C5C5C5",
                  }}
                  onClick={handleClearText}
                >
                  <ClearIcon sx={{ color: "#626262" }} />
                </Button>

                {/* word count */}
                <TextField
                  id="word-count_J1"
                  label=""
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {wordCount}/{maxChars}文字
                      </InputAdornment>
                    ),
                    sx: {
                      fontSize: 13,
                      maxWidth: "110px",
                      height: "38px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderTop: 0,
                      borderRight: 0,
                      borderLeft: 0,
                      borderTopLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderTopRightRadius: 0,
                    },
                    "& :hover .MuiOutlinedInput-notchedOutline": {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTop: 1,
                      borderRight: 1,
                      borderLeft: 1,
                    },
                    "& :forcus .MuiOutlinedInput-notchedOutline": {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTop: 1,
                      borderRight: 1,
                      borderLeft: 1,
                    },
                  }}
                />

                {/* ask-gpt button */}
                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<TranslateSharpIcon />}
                  sx={{
                    fontSize: 14,
                    textTransform: "capitalize",
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  disabled={loading}
                  onClick={handleChatGPT}
                >
                  {loading ? "..." : "尋ねる"}
                </Button>
              </Box>
            </Grid>

            {/* GPT-answers */}
            <Grid xs={6} sm={4} md={6}>
              <TextField
                fullWidth
                multiline
                minRows={6}
                maxRows={10}
                InputProps={{
                  style: { fontSize: 16, lineHeight: 1.5 },
                }}
                id="ChatGPT_answer"
                label=""
                placeholder="AIから複数の提案が出力されます"
                variant="outlined"
                sx={{
                  background: "#f1f3f4",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderLeft: 1,
                    borderColor: "#c5c5c5",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                  "& :hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#c5c5c5",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 1,
                    borderColor: "#c5c5c5",
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>

          {/* 英語のパラグラフを考える */}
          <Grid item xs={12} sx={{ p: 0, mt: 1 }}>
            <Typography variant="h5" fontWeight="bold">
              英語のパラグラフを作文する
            </Typography>
            <Typography variant="caption">
              ここでは、上で考えたセンテンスを使ったり、文法の校正チェックと日本語への翻訳を行ったりし、パラグラフを完成させます。
              <br />
            </Typography>
            <Grid
              container
              columns={{ xs: 6, sm: 8, md: 12 }}
              sx={{ px: 0, mt: 1 }}
              spacing={0}
            >
              <Grid xs={6} sm={4} md={6}>
                <BodyTextbox1Para
                  updatteValue={props?.paragraph?.english_text}
                  setJapanesTranslation={setParagraphSupportOutput}
                  setEnglishText={setParagraphSupportInpput}
                  setSaved={props?.setSaved}
                />
              </Grid>

              {/* Translated Japanese */}
              <Grid xs={6} sm={4} md={6}>
                <TextField
                  fullWidth
                  multiline
                  minRows={10}
                  maxRows={20}
                  InputProps={{
                    style: { fontSize: 16, lineHeight: 1.5 },
                  }}
                  id="Japanese2_C3"
                  label=""
                  placeholder="翻訳された日本文が表示されます"
                  variant="outlined"
                  sx={{
                    background: "#f1f3f4",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderLeft: 1,
                      borderColor: "#c5c5c5",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    },
                    "& :hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#c5c5c5",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 1,
                      borderColor: "#c5c5c5",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    },
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>

          {/* evaluation  */}
          <Grid item xs={12} sx={{ p: 0, mt: 0 }} spacing={0}>
            <Typography variant="h5" fontWeight="bold" sx={{ mt: 1 }}>
              評価する
            </Typography>
            <Typography variant="caption">
              完成したパラグラフが、適切な構造・内容となっているか評価します。
            </Typography>
            <BodyTextbox1C3Paragraph questionType={questionType} />
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} sm={3} md={3}>
        {authStatus === "authenticated" ? (
          <Box sx={{ marginBottom: "8px" }}>
            <Typography
              sx={{ marginBottom: "5px", fontSize: "12px", color: "red" }}
            >
              {validateText}
            </Typography>
            <LoadingButton
              onClick={handleSave}
              sx={{ width: "100%", marginBottom: "8px" }}
              variant="contained"
              loading={isUpdate}
              disableElevation
            >
              <span>保存する</span>
            </LoadingButton>
            <Typography
              variant="p"
              sx={{
                color: "text.primary",
                fontSize: 14,
                display: "flex",
                justifyContent: "end",
              }}
            >
              {updatedTime}
            </Typography>
          </Box>
        ) : null}
        <RightSide />
      </Grid>
    </Grid>
  );
}
